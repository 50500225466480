/*------------------------------------*\
		Header
\*------------------------------------*/
.header {
  background-color: $white;

  &__utils {
    z-index: z('modal', 20);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate3d(0, -50%, 0);
    transform-style: preserve-3d;
    perspective: 1000px;

    ul {
      @include reset-ul;
      font-size: 0;
      line-height: 1rem;

      li {
        display: inline-block;
        height: 1rem;
        padding: 0 1.2rem;
        vertical-align: middle;
        border-left: 1px solid $grey-lighter;

        &:first-child {
          border-left: 0;
        }

        &.header__utils-lang-selector {
          display: none !important;
        }

        @media #{md("s")} {
          &.header__utils-lang-selector {
            display: inline-block !important;
          }

          &:last-child {
            padding-right: 0;
          }

          &.header__utils-search-item {
            padding-left: 0;
            border-left: 0;
          }
        }
      }
    }

    a {
      display: inline-block;

      svg {
        display: inline-block;
        vertical-align: middle;

        &.icon--locker {
          color: red;
          fill: $grey-mid;
        }
      }

      * {
        transition: all 0.3s ease;
      }

      &:hover {
        .icon--user {
          * {
            stroke: $grey-dark !important;
          }
        }

        .icon--logout {
          * {
            fill: $grey-dark !important;
          }
        }
      }
    }
  }
}

.selector-lang {
  line-height: 1rem;

  a {
    cursor: pointer;

    &:focus,
    &:active {
      &.current {
        &:after {
          transform: rotate(-180deg);
        }
      }

      + ul {
        visibility: visible;
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
      }
    }
  }

  a {
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    color: $grey;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    transition: all 0.3s;

    &:hover {
      color: $grey-dark;
    }

    &.current {
      padding-right: 1.2rem;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 8px;
        height: 5px;
        margin-top: -3px;
        background: transparent url(#{$svg-dir}arrow-down.svg) center center no-repeat;
        background-size: contain;
        transition: all 0.3s ease;
      }
    }
  }

  ul {
    z-index: 1403;
    position: absolute;
    top: 100%;
    left: 50%;
    padding: 7px 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0 8px 15px rgba($grey-dark, 0.2);
    opacity: 0;
    transform: translate3d(-50%, 10px, 0);
    transition: all 0.3s $in-out-quad;

    li {
      display: block;
      height: auto;
      padding: 0;
      border: none;

      a {
        padding: 0.2em 1rem;
      }
    }
  }
}

.header__utils-search-item {
  svg {
    height: 16px !important;
    width: auto;
  }
}

.header__top {
  z-index: 1403;
  display: flex;
  align-items: flex-start;
  padding: $space $space-xs * 2 $space-xs * 1.4;
  text-align: left;

  // Hide the small typo on the logo: PARIS
  .logo--s {
    display: none;
  }
}

.logo {
  padding-left: 2 * $space;

  svg {
    display: inline-block;
    width: 88px;
  }
}

.header__burger {
  display: inline-block;
  cursor: pointer;

  svg {
    display: block;
  }
}

// WPML Language Selector Styles
.header {
  .wpml-ls-legacy-dropdown {
    z-index: z('goku');
    position: absolute;
    top: 2 * $space-xs;
    right: 2 * $space-xs;
    width: 6rem;

    a {
      padding-top: 1 * $space-xs;
      padding-bottom: 1 * $space-xs;
      border: 0 none;
    }

    .wpml-ls-sub-menu {
      a {
        padding-top: 2 * $space-xs;
        padding-bottom: 2 * $space-xs;
      }
    }
  }
}

@media #{md("s")} {
  .header__top {
    display: block;
    padding: $space-xs * 2 0 $space-xs;
    text-align: center;

    // Display PARIS
    .logo--s {
      display: block;
    }
  }

  // Larger logo
  .logo {
    padding-left: 0;

    svg {
      width: 200px;
    }
  }

  // No burger on this side
  .header__burger {
    display: none;
  }

  .login__link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;
    opacity: 0.7;

    svg {
      top: 1px;
      display: initial !important;
      width: 14px;
      height: 10px;
      vertical-align: initial !important;
      transform: rotate(-90deg);
      transition: transform 0.5s $out-quad;
    }

    span {
      display: inline-block;
      width: auto;
      margin-right: 5px;
      font-size: 12px;
    }

    &:hover {
      svg {
        transform: rotate(-90deg) translateY(3px);
      }
    }

    @media #{md("s", "max")} {
      display: none;
    }
  }
}
