.section__refs {
  margin-bottom: 4.5em;

  @media #{md('m')} {
    margin-bottom: 8.5em;
  }
}

.refs {
  width: 100%;
  height: 100%;

  &.js-reveal {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.9s $in-out-expo, opacity 0.45s;

    &.is-visible {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.ref {
  &__container {
    white-space: nowrap;

    @media #{md('m')} {
      height: 500px;
      border: 1px solid $grey-lightest;
    }
    @media #{md('xl')} {
      height: 600px;
    }
  }

  &__pointer {
    @include round(66, 66);
    z-index: z('dropdown');
    position: absolute;
    top: -33px;
    left: calc(30% + 33px);
    display: none;
    cursor: grab;
    cursor: -webkit-grab;
    box-shadow: none;
    transition: opacity 0.25s, visibility 0.25s;

    &:active {
      cursor: grabbing;
      cursor: -webkit-grabbing;
    }

    &:before {
      content: '';
      z-index: z('default');
      position: absolute;
      top: 50%;
      left: 50%;
      width: 28px;
      height: 10px;
      margin-top: -5px;
      margin-left: -14px;
      background: transparent url(#{$svg-dir}layout/double-arrows.svg) center center no-repeat;
      background-size: contain;

      // @media #{md('s')} {
      // 	margin-left: -29px;
      // 	margin-top: -7px;
      // 	width: 58px;
      // 	height: 14px;
      // }
    }

    @media #{md('m')} {
      @include round(42, 42);
      top: 15%;
      left: calc(80% - 12px);
      display: block;
      visibility: hidden;
      opacity: 0;

      .section__refs:hover & {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__content {
    left: 0;
    width: 100%;
    //transition: width 0.5s $in-out-circ;
    margin-bottom: 40px;
    overflow: hidden;
    vertical-align: top;

    .hentry {
      margin: 0;
      white-space: initial;

      @media #{md('m')} {
        top: 50%;
        width: 70%;
        margin-left: 15%;
        transform: translate3d(0, -50%, 0);
      }
    }

    @media #{md('m')} {
      display: inline-block;
      width: 80%;
      height: 100%;
      // position: absolute;
      // top: 50%;
      // transform: translate3d(0, -50%, 0);
      margin-bottom: 0;
    }
  }

  &__items {
    display: inline-block;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;

    @media #{md('m')} {
      overflow-y: visible;
    }
  }

  &-item {
    $self: &;
    @include clearfix;
    display: inline-block;
    max-width: 90%;
    padding: 0 0.75rem;
    white-space: nowrap;
    transition: margin-left 0.5s $in-out-circ;

    // &.active {
    // 	@media #{md('m')} {
    // 		margin-left: 80%;
    // 	}

    // 	@media #{md('xxl')} {
    // 		margin-left: 80%;
    // 	}
    // }

    &--img {
      display: flex;
      align-items: center;
      max-width: 100%;
      transition: margin-left 0.5s $in-out-circ;

      img {
        display: block;
        max-width: 100%;

        @media #{md('m')} {
          //margin-top: calc(60px + 0.9em);
          max-width: none;
          max-height: 100%;
        }

        @media #{md('xl')} {
          //margin-top: calc(60px + 0.9em);
          max-height: none;
        }
      }

      &--content {
        @media #{md('l')} {
          height: 73%;
        }
        @media #{md('xl')} {
          height: auto;
        }
      }

      &--legend {
        display: block;
        margin: 15px 0;
        font-size: 0.75em;
        font-weight: 600;
        line-height: 1.2em;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.2em;

        @media #{md('m')} {
          position: absolute;
          top: 100%;
          left: 0;
          margin: 20px 0;
        }
        @media #{md('xl')} {
          margin: 30px 0;
        }
      }

      @media #{md('m')} {
        float: right;
        max-width: none;
        height: 100%;
      }
    }

    @media #{md('m')} {
      max-width: none;
      height: 100%;
      padding: 0 15px;
    }
  }
}
