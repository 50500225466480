/*------------------------------------*
	Hero
*------------------------------------*/
@import '../base/variables';

.hero {
  height: 420px;

  // &::before {
  // 	content: '';
  // 	position: absolute;
  // 	top: 0;
  // 	left: 0;
  // 	display: block;
  // 	width: 100%;
  // 	height: 100%;
  // 	background-color: $grey-dark;
  // 	opacity: .15;
  // }

  .t-large {
    margin-bottom: 40px;
  }

  .u-dt {
    width: 100%;
    height: 100%;
  }

  .u-dtc {
    vertical-align: middle;
  }

  &.js-blazy {
    opacity: 0;
    transition: opacity 0.8s $out-expo;

    &.b-loaded {
      opacity: 1;
    }
  }

  &__link {
    //top: calc(200px - 44px);
    left: 0.75rem;
    display: inline-block;

    &:hover {
      span {
        transform: rotate(-90deg) translate3d(10px, 50%, 0);
      }
    }

    &.sticky {
      &.fixed {
        .icon {
          @media #{md("m")} {
            position: fixed;
            top: 0;
            left: 10px;
            transform: translate3d(0, 0, 0);
          }
          @media #{md("l")} {
            left: 7vw;
          }
        }
      }
    }

    .icon {
      @include round;
      display: inline-block;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }

      @media #{md("m")} {
        z-index: z('default');
        position: absolute;
        top: 0;
        left: 50%;
        margin-top: 10px;
        transform: translate3d(-50%, 0, 0);
      }
    }

    span {
      width: 100%;
      margin-left: 15px;
      font-size: 0.75em;
      font-weight: 600;
      color: $grey;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      white-space: nowrap;
      transition: all 0.3s;

      i {
        font-style: normal;
        @media #{md("m")} {
          float: right;
        }
      }

      @media #{md("m")} {
        position: absolute;
        top: 100%;
        right: 50%;
        margin-top: 0em;
        margin-left: 0;
        text-align: right;
        transform: rotate(-90deg) translate3d(0, 50%, 0);
        transform-origin: bottom right;
      }
    }

    @media #{md("m")} {
      z-index: z('dropdown');
      left: 10px;
      width: 88px;
      margin-left: 0;
      padding-top: 98px;

      &.sticky {
        position: absolute !important;
        top: calc(65vh - 54px) !important;
      }
    }

    @media #{md("l")} {
      left: 7vw;

      &.sticky {
        top: calc(70vh - 54px) !important;
      }
    }
  }

  &__page {
    display: flex;
    align-items: center;
    width: 100%;
    height: 420px;
    margin-bottom: 2rem;
    vertical-align: middle;

    &.no-img {
      height: 3.5rem;
      margin-bottom: 0rem;

      @media #{md("s")} {
        margin-bottom: 0;
      }
      @media #{md("m")} {
        height: 6rem;
      }

      @media #{md("l")} {
        height: 6rem;
        margin-bottom: 1.5em;
      }
    }

    .grid {
      width: 100%;
    }

    &--img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    figure {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      opacity: 0;
      transform: scale3d(1.15, 1.15, 1);
      transition: all 0.8s $in-out-quad;

      &.is-loaded {
        opacity: 1;
        transform: scale3d(1, 1, 1);
      }
    }

    &--content {
      color: #fff;
    }

    &--desc {
      display: none;

      @media #{md('m')} {
        display: block;
      }
    }

    &--title {
      margin: 0;

      font-size: 2.15rem;
      font-weight: 400;
      line-height: 1.28em;

      @media #{md('m')} {
        font-size: 3em;
      }
      @media #{md('xl')} {
        font-size: 3.5em;
      }
    }

    @media #{md("s")} {
      height: 65vh;
    }

    @media #{md("m")} {
      margin-bottom: 5em;
    }
    @media #{md("l")} {
      height: 70vh;
      margin-bottom: 8em;
    }
  }

  &__slider {
    $self: &;
    height: 50vw;
    overflow: hidden;
    white-space: nowrap;

    .slick-dots {
      @include reset-ul;
      z-index: 1400;
      position: absolute;
      top: auto;
      right: 30px;
      bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 0.3em;

      li {
        width: 10px;
        height: 10px;
        margin: 0.2em 0.3em;
        overflow: hidden;
        color: transparent;
        background: #a0a0a0;
        border-radius: 100%;

        &.slick-active {
          background: #23282d;
        }

        @media #{md("xs", "max")} {
          width: 8px;
          height: 8px;
          margin: 0.2em;
        }
      }
    }

    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide > div {
      height: 100%;
    }

    .slick-slide {
      &.slick-cloned {
        #{$self} {
          &--slide {
            &--img {
              transform: translate3d(0, 0, 0) !important;
            }
          }
        }
      }

      &.slick-active {
        #{$self} {
          &--slide {
            &--img {
              //transition: none;
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }
    }

    &--slide {
      width: 100%;
      height: 100%;
      overflow: hidden;

      &--img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        @media #{md('m')} {
          //transform: translate3d(-20%, 0, 0);
          //transition: all 1.2s $in-out-quart;

          .inverse & {
            //transform: translate3d(-50%, 0, 0);
          }
        }
      }

      &--content {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translate3d(0, -50%, 0);
      }
    }

    &--video {
      .grid__row {
        position: relative;
      }

      video {
        @media #{md('m')} {
          width: 100%;
        }
      }

      .modal__open-btn {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    @media screen and (min-width: 111em) {
      height: 920px;
    }

    @media #{md("s", "max")} {
      height: 39vw;
    }

    @media #{md("xs", "max")} {
      height: 135vw;
      white-space: initial;
    }
  }
}

.home {
  .hero,
  .hero__background {
    height: 50vw;
    background-size: 100%;

    @media screen and (min-width: 111em) {
      height: 920px;
    }

    @media #{md("s", "max")} {
      height: 39vw;
    }

    @media #{md("xs", "max")} {
      height: 135vw;
    }
  }

  // .slick-dots {
  // 	z-index: 1400;
  // 	top: -20px;
  // 	display: flex;
  // 	align-items: center;
  // 	justify-content: flex-end;
  // 	padding-right: 0.3em;

  // 	li {
  // 		background: #a0a0a0;
  // 		margin: 0.2em 0.3em;
  // 		width: 10px;
  // 		height: 10px;
  // 		overflow: hidden;
  // 		color: transparent;
  // 		border-radius: 100%;

  // 		&.slick-active {
  // 			background: #23282d;
  // 		}

  // 		@media #{md("xs", "max")} {
  // 			width: 8px;
  // 			height: 8px;
  // 			margin: 0.2em;
  // 		}
  // 	}
  // }
}

.hero__handler {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero__handler.slick-slider {
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div {
    height: 100%;
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.hero__background {
  width: 100%;
  height: 420px;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
}

.archive-products {
  .hero {
    h1 {
      visibility: hidden;
    }
  }
}

// White version
.hero--white {
  color: $white;
}

.hero-small {
  @include clearfix;
  padding: 2 * $space 0;

  @media #{md("s")} {
    padding: 5 * $space 0;
  }
}
