/*------------------------------------*\
		Signature Studio Meta
\*------------------------------------*/

.studio-meta {
  display: block;
  overflow: hidden;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    .icon--hover {
      width: 100%;
    }
  }

  span {
    display: inline-block;
  }
}
