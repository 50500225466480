/*------------------------------------*
	Page glossary / lexique
*------------------------------------*/
.glossary__icon {
  display: flex;
  margin-right: 19px;
  margin-bottom: 24px;

  img {
    display: inline-block;
    vertical-align: middle;
  }

  + .glossary__title--0 {
    margin-top: 8rem;
  }

  + .glossary__title--1 {
    margin-top: 3rem;
  }
}

.glossary__label {
  padding-left: 1rem;
  font-size: 0.93rem;
  line-height: 1.6;
}

.glossary__title {
  margin-bottom: 3.5rem;
  font-family: $condensed;
  font-size: 3rem;
  line-height: 1;
}

.glossary__title--1 {
  margin-bottom: 2.25rem;
  font-size: 1.5rem;
}

/*------------------------------------*
	Sidebar specifix stlyes
*------------------------------------*/
.glossary__sidebar {
  .glossary__icon {
    display: inline-block;
    padding: 0.4375rem;
    border: solid 1px $grey-dark;

    img {
      display: block;
    }

    + .glossary__title--0 {
      margin-top: 1.5rem;
    }

    + .glossary__title--1 {
      margin-top: 0;
    }
  }

  .glossary__title {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    line-height: 1;
  }

  .glossary__title--1 {
    margin-bottom: 1.125rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  @media #{md('s', 'max')} {
    display: none;
  }
}
