/*------------------------------------*\
		Main
\*------------------------------------*/

.main {
  flex-grow: 1;
  width: $layout-main--width;
  max-width: $layout-main--max-width;
  margin-right: auto;
  margin-left: auto;
}

.main > .section:only-child,
.main > .section:only-child > article {
  height: 100%;
}

.sticky {
  &.fixed {
    @media #{md('m')} {
      position: fixed;
      top: 0 !important;
    }
  }
}

.line-breaker {
  &.js-reveal {
    & > span {
      display: inline-block;
      overflow: hidden;
      vertical-align: middle;

      span {
        display: inline-block;
        vertical-align: middle;
        transform: translate3d(0, 100%, 0);
        transition: all 0.6s;
      }
    }

    &.is-visible {
      & > span {
        span {
          transform: translate3d(0, 0, 0);
          transition-delay: 0.4s;
        }

        @for $i from 0 through 10 {
          &:nth-child(#{$i + 1}) {
            &:after {
              animation-delay: #{$i * 0.05s};
            }

            span {
              transition-delay: #{$i * 0.05s};
            }
          }
        }
      }
    }
  }
}

.js-reveal {
  .inner-img {
    display: block;
    overflow: hidden;
    transform: translate3d(0, 100%, 0);
    transition: all 0.8s $in-out-circ;

    img {
      transform: translate3d(0, -100%, 0);
      transition: all 0.8s $in-out-circ;
    }
  }

  &.is-visible {
    .inner-img {
      transform: translate3d(0, 0, 0);

      img {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
