/*---------------------------------------*\
		Positions & dispositions
\*---------------------------------------*/

@use 'sass:math';

@mixin clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

/*---------------------------------------*\
		Typographie
\*---------------------------------------*/

@mixin fontfaces($webfonts, $dir) {
  @each $webfont in $webfonts {
    @font-face {
      font-family: nth($webfont, 1);
      font-style: #{nth($webfont, 4)};
      font-weight: #{nth($webfont, 3)};
      src: url('#{$dir}#{nth($webfont, 2)}.woff2') format('woff2'),
        url('#{$dir}#{nth($webfont, 2)}.woff') format('woff'),
        url('#{$dir}#{nth($webfont, 2)}.ttf') format('truetype');
    }
  }
}

@mixin reset-ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

// Nicely hyphenate long words
// cf. https://justmarkup.com/log/2015/07/31/dealing-with-long-words-in-css/
@mixin hyphenate {
  hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/**
 * Responsive typograhy
 * @author Mike Riethmuller http://codepen.io/MadeByMike/pen/YPJJYv
 * @param  {integer} $min-width: 0            The breakpoint minimum to activate the responsive typography
 * @param  {integer} $max-width: 2560         The breakpoint maximum to activate th responsive typography
 * @param  {integer} $min-font:  12           The minimum font-size for the element
 * @param  {integer} $max-font:  16           The maximum font-size for the element
 * @return {void}
 */
@mixin responsive-type($min-width: 0, $max-width: 2560, $min-font: 12, $max-font: 16) {
  // Set min size
  font-size: $min-font * 1px;

  // Adjust size between `$min-width` et `$max-width`
  @media (min-width: #{$min_width}px) and (max-width: #{$max_width}px) {
    font-size: calc(
      #{$min_font}px + (#{$max_font} - #{$min_font}) *
        ((100vw - #{$min_width}px) / (#{$max_width} - #{$min_width}))
    );
  }

  // Set max size
  @media (min-width: #{$max_width}px) {
    font-size: #{$max_font}px;
  }
}

/*---------------------------------------*\
		Triangles
\*---------------------------------------*/

@mixin triangle-up($triangle-up-width, $triangle-up-height, $triangle-up-color) {
  width: 0;
  height: 0;
  border-color: transparent transparent $triangle-up-color;
  border-style: solid;
  border-width: 0 $triangle-up-width * 0.5 $triangle-up-height;
}

@mixin triangle-right($triangle-right-width, $triangle-right-height, $triangle-right-color) {
  width: 0;
  height: 0;
  border-color: transparent transparent transparent $triangle-right-color;
  border-style: solid;
  border-width: $triangle-right-height * 0.5 0 $triangle-right-height * 0.5 $triangle-right-width;
}

@mixin triangle-down($triangle-down-width, $triangle-down-height, $triangle-down-color) {
  width: 0;
  height: 0;
  border-color: $triangle-down-color transparent transparent;
  border-style: solid;
  border-width: $triangle-down-height $triangle-down-width * 0.5 0;
}

@mixin triangle-left($triangle-left-width, $triangle-left-height, $triangle-left-color) {
  width: 0;
  height: 0;
  border-color: transparent $triangle-left-color transparent transparent;
  border-style: solid;
  border-width: $triangle-left-height * 0.5 $triangle-left-widt $triangle-left-height * 0.5 0;
}

/*---------------------------------------*\
		SVG Responsive
\*---------------------------------------*/

/*
Utilitaire pour le padding hack qui permet d'avoir des fichiers `*.svg` responsive.
Plus d'infos ici : http://tympanus.net/codrops/2014/08/19/making-svgs-responsive-with-css/
*/

@mixin padding-hack($svg-width, $svg-height, $container-width) {
  width: $container-width;
  height: 0;
  padding-top: strip-units(math.div($svg-height, $svg-width) * $container-width) * 1%;
}

/*------------------------------------*\
		Gradients
\*------------------------------------*/

/**
 * Mixin printing a linear-gradient
 * as well as a plain color fallback
 * and the `-webkit-` prefixed declaration
 * @param {String | List | Angle} $direction - Linear gradient direction
 * @param {Arglist} $color-stops - List of color-stops composing the gradient
 */
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: linear-gradient($direction, $color-stops);
}

/*------------------------------------*
	Shadow
*------------------------------------*/
@mixin shadow {
  box-shadow: 8px 8px 50px 1.2px rgba(0, 0, 0, 0.05);
}

@mixin round($width: 88, $height: 88) {
  display: inline-block;
  width: #{$width}px;
  height: #{$width}px;
  background: #fff;
  border: none;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 10px rgba($grey-dark, 0.1);

  &:after {
    @include linear-gradient(135deg, rgba($grey-dark, 0.4) 0%, rgba($grey-dark, 0) 100%);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    padding-top: 90%;
    border-radius: 50%;
    opacity: 0.1;
    transform: translate3d(-50%, -50%, 0);
  }
}
