/*------------------------------------*\
		Grid system (Bootstrap)
\*------------------------------------*/

// Container
@use 'sass:math';

$grid-prefix: 'legacy-';

.#{$grid-prefix}grid {
  margin-right: auto;
  margin-left: auto;
}

// Row
.#{$grid-prefix}grid__row {
  @include clearfix;

  // Media queries
  @each $breakpoint in $breakpoints {
    $key: nth($breakpoint, 1);
    $grid-gutter: map-get($grid-gutters, $key);

    @media print, #{md($key)} {
      margin-right: $grid-gutter * -0.5;
      margin-left: $grid-gutter * -0.5;
    }
  }
}

// Grid and cols
.#{$grid-prefix}grid,
[class*='#{$grid-prefix}grid__col-'] {
  min-height: 1px;

  // Media queries
  @each $breakpoint in $breakpoints {
    $key: nth($breakpoint, 1);
    $grid-gutter: map-get($grid-gutters, $key);

    @media print, #{md($key)} {
      padding-right: $grid-gutter * 0.5;
      padding-left: $grid-gutter * 0.5;
    }
  }
}

[class*='#{$grid-prefix}grid__col-'] {
  float: left;
}

// Generates the columns class for
// each breakpoints defined
@each $breakpoint in $breakpoints {
  $key: nth($breakpoint, 1);
  $grid-gutter: map-get($grid-gutters, $key);

  // Media queries
  @media #{md($key)} {
    [class*='#{$grid-prefix}grid__col-#{$key}--'] {
      display: block;
    }

    .#{$grid-prefix}grid__col-#{$key}--no-gutter {
      padding-right: 0;
      padding-left: 0;
    }

    .#{$grid-prefix}grid__col-#{$key}--gutter {
      padding-right: $grid-gutter * 0.5;
      padding-left: $grid-gutter * 0.5;
    }

    .#{$grid-prefix}grid__col-#{$key}--left {
      float: left;
    }
    .#{$grid-prefix}grid__col-#{$key}--right {
      float: right;
    }
    .#{$grid-prefix}grid__col-#{$key}--0 {
      display: none;
    }

    // Generate all columns classes
    @for $i from 1 through $grid--columns {
      .#{$grid-prefix}grid__col-#{$key}--#{$i} {
        width: math.div($i * 100%, $grid--columns);
      }

      .#{$grid-prefix}grid__col-#{$key}-push--#{$i} {
        margin-left: math.div($i * 100%, $grid--columns);
      }

      .#{$grid-prefix}grid__col-#{$key}-push--0 {
        margin-left: 0;
      }
    }
  }
}

.#{$grid-prefix}grid--nested {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
