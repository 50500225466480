/*------------------------------------*
	Facets
*------------------------------------*/
@import '../vendors/selectric';

// !!!
.facetwp-counter {
  display: none !important;
}

.facets {
}

.facets__group {
  padding: $space 0;

  &.facets__group--small {
    padding: 0 0 $space;
  }
}

.facets__group--border {
  border-bottom: 1px solid $grey-light;
}

.facets__title {
  padding-bottom: $space;
  font-weight: 600;
}

.facets__el {
  display: flex;
}

.facetwp-counter {
  padding-left: 4px;
  color: rgba($grey-dark, 0.6);
}

.facets__item {
  padding-bottom: $space-xs !important;

  .form__label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: $space;
    cursor: pointer;

    &:hover {
      color: rgba($grey-dark, 0.6);
    }

    .checkbox {
      position: absolute;
      top: 50%;
      left: 0;
      width: 12px;
      height: 12px;
      overflow: hidden;
      border: 1px solid $grey;
      transform: translate(0, -50%);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $grey;
        border-radius: 50%;
        transform: scale(0);
        transition: 0.4s transform $out-expo;
      }
    }
  }

  &.checked {
    .form__label {
      .checkbox {
        &::after {
          transform: scale(1.5);
          transition: 0.4s transform $out-back;
        }
      }
    }
  }
}

.facetwp-toggle {
  display: inline-block;
  width: 100%;
  padding-top: $space-xs * 0.5;
  cursor: pointer;

  &:hover {
    color: rgba($grey-dark, 0.8);
  }
}

.facetwp-color {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: $space-xs !important;
  margin-bottom: $space-xs !important;
  overflow: visible;
  cursor: pointer;
  background-color: $grey-light;
  border-radius: 50%;
  box-shadow: none !important;

  &.checked {
  }
}

.facets__checkbox {
  padding-bottom: $space-xs;
}

.form__checkbox {
  &:checked {
    + .form__label {
      .checkbox {
        &::after {
          transform: scale(1.5);
          transition: 0.4s transform $out-back;
        }
      }
    }
  }

  + .form__label {
    padding-left: $space;
    cursor: pointer;

    .checkbox {
      position: absolute;
      top: 50%;
      left: 0;
      width: 12px;
      height: 12px;
      overflow: hidden;
      border: 1px solid $grey;
      transform: translate(0, -50%);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $grey;
        border-radius: 50%;
        transform: scale(0);
        transition: 0.4s transform $out-expo;
      }
    }
  }
}

.facets__checkbox--colors {
  .form__checkbox {
    &:checked {
      + .form__label {
        .checkbox {
          &::after {
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }
    }

    + .form__label {
      .checkbox {
        width: 16px;
        height: 16px;
        overflow: visible;
        background-color: $grey-dark;
        border-radius: 50%;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 20px;
          height: 20px;
          background-color: transparent;
          border: 1px solid $grey-dark;
          transform: translate(-50%, -50%) scale(0);
        }
      }
    }
  }
}

.facetwp-input-wrap {
  display: block;
  width: 100%;
}

.facetwp-search-wrap {
  display: block;
  width: 100%;

  .facetwp-btn {
    top: 50%;
    right: $space-xs * 2;
    transform: translate(0, -50%);
  }
}

/*------------------------------------*
	Radio
*------------------------------------*/
.facetwp-type-range_list .facetwp-radio {
  &:hover {
    color: rgba($grey-dark, 0.6);
  }
}

/*------------------------------------*
	Colors Teinte
*------------------------------------*/
.facetwp-color.checked::after {
  margin: 10px 0 0 6px !important;
}

.facetwp-color {
  transition: 0.4s transform $out-expo;

  &:hover {
    transform: scale(1.1);
    transition: 0.4s transform $out-expo;
  }
}

.facetwp-color[data-value='jaunes'] {
  @include linear-gradient(90deg, #996739, #d2a635, #fdcf2f, #fed763, #fdf1a3);
}

.facetwp-color[data-value='beiges'] {
  @include linear-gradient(90deg, #213127, #5a4c34, #94866d, #bbb199, #efede1);
}

.facetwp-color[data-value='gris'] {
  @include linear-gradient(90deg, #213127, #7c7e79, #9a9e9f, #c3c8ce, #ffffff);
}

.facetwp-color[data-value='rouges-et-oranges'] {
  @include linear-gradient(90deg, #881e28, #bc0e2b, #d3573b, #f68d25, #f8b43e);
}

.facetwp-color[data-value='oranges-et-rouges'] {
  @include linear-gradient(90deg, #f68d25, #f8b43e, #d3573b, #bc0e2b, #881e28);
}

.facetwp-color[data-value='verts'] {
  @include linear-gradient(90deg, #16482f, #7e8158, #959c4d, #c6c238, #e0dda8);
}

.facetwp-color[data-value='bleus'] {
  @include linear-gradient(90deg, #1c4374, #194c9e, #5985b9, #7bb2d2, #c6d6e5);
}

.facetwp-color[data-value='turquoises'] {
  @include linear-gradient(90deg, #095970, #189eb5, #84d0d4, #b5ecdb, #fcfefb);
}

.facetwp-color[data-value='roses'] {
  @include linear-gradient(90deg, #70273d, #ac1550, #ba5c89, #dbb5b5, #fdeef1);
}

.facetwp-color[data-value='violets'] {
  @include linear-gradient(90deg, #4a3353, #575397, #7e74ab, #969bc7, #c0b5d7);
}

.facetwp-color[data-value='brique'] {
  @include linear-gradient(90deg, #c68006, #e99755, #f8b43e);
}

/*------------------------------------*
	Checkbox Nouveauté "N"
*------------------------------------*/
.facets__checkbox[data-value='n'] {
  display: none;
}
