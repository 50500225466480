.modal {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.modal__modal {
  z-index: 9999;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.modal__overlay {
  z-index: -1;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.8);
}

.modal__wrapper {
  height: 100%;
}

.modal__body {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.modal__container {
  z-index: 2;
  position: relative;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: auto;
}

.modal__video {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .modal__video-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    cursor: pointer;

    svg {
      width: 30px;
      height: 30px;

      &:hover {
        color: grey;
      }
    }
  }

  video {
    width: 100%;
    height: auto;

    @media #{md('m')} {
      width: auto;
      max-height: 600px;
    }
  }
}
