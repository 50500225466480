/*------------------------------------*\
		Page d'accueil
\*------------------------------------*/

.full-pic {
  display: block;
  width: 100%;
  height: auto;
}

.shadow {
  @include shadow;
}

.content {
  h3 {
    margin-bottom: 30px;
    font-size: 0.875em;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  p {
    margin-bottom: 40px;
    font-weight: 300;
    line-height: $space;
    color: $grey-dark;
  }

  strong {
    font-weight: 600;
  }
}

/* CATEGORIES */
.home-categories {
  margin-bottom: 0;

  @media #{md("s")} {
    margin-bottom: $space * 3;
  }

  @media #{md("l")} {
    margin-bottom: $space * 7;
  }
}

.home-categories-right {
  @media #{md("s","max")} {
    padding: 0;
  }

  @media #{md("s")} {
    margin-top: -100px;

    .category-thumb {
      .category-thumb__picture {
        transform: translate3d(-100%, 0, 0);

        .inner {
          transform: translate3d(100%, 0, 0);
        }
      }

      &:first-child {
        max-width: 313px; //72.8%;

        .category-thumb__picture {
          transform: translate3d(0, 100%, 0);

          .inner {
            transform: translate3d(0, -100%, 0);
          }
        }
      }
    }
  }
}

.home-categories-left {
  padding-top: 50px;
  @media #{md("s","max")} {
    padding-right: 0;
    padding-left: 0;
  }

  @media #{md("s")} {
    margin-top: 80px;

    .category-thumb {
      &:nth-child(2),
      &:nth-child(3) {
        float: right;
      }

      &:nth-child(2),
      &:nth-child(3) {
        max-width: 313px; //72.8%;
      }

      &:nth-child(2) {
        width: 313px;
        height: 420px;
      }

      &:nth-child(3) {
        width: 313px;
        height: 295px;
      }
    }
  }
}

.home-categories__content {
  float: none;
  padding: 50px 0;

  p {
    margin-bottom: 0;
  }

  @media #{md("s")} {
    padding: 200px 0 60px;
  }

  @media #{md("m")} {
    padding: 200px 0 140px;
  }
}

.category-thumb {
  // @include shadow;
  display: block;
  margin-bottom: $space * 0.5;

  a {
    display: block;
    text-decoration: none;

    .underline-effect {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: rgba(0, 0, 0, 0.4);
        transform: scaleX(0);
        transform-origin: 100% 0;
        transition: transform 0.8s $out-expo;
      }
    }

    &:hover {
      .underline-effect::after {
        transform: scaleX(1);
        transform-origin: 0 0;
      }
    }
  }

  @media #{md("s")} {
    margin-bottom: 50px;
  }
}

// Variations
@media #{md("s")} {
  .category-thumb--right {
    float: right;
  }

  .category-thumb--small {
    max-width: 313px; //72.8%;
  }
}

.category-thumb--dark {
  .category-thumb__caption {
    color: $grey-dark;
  }
}

// Images
.category-thumb__picture {
  overflow: hidden;

  .js-reveal & {
    overflow: hidden;
    transform: translate3d(100%, 0, 0);
    transition: all 0.7s $in-out-circ;

    .inner {
      display: block;
      width: 100%;
      overflow: hidden;
      transform: translate3d(-100%, 0, 0);
      transition: all 0.7s $in-out-circ;
    }
  }

  .is-visible & {
    transform: translate3d(0, 0, 0) !important;

    .inner {
      transform: translate3d(0%, 0, 0) !important;
    }
  }

  &::before {
    content: '';
    z-index: z('default');
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.6);
    opacity: 0;
    transition: 0.4s all $out-expo;
  }
}

// Légende
.category-thumb__caption {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: $condensed;
  font-size: $space;
  color: $white;
  text-align: center;
  letter-spacing: 2px;
  margin-top: 4px;

  .js-reveal & {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
    transition: all 0.4s;
  }

  .is-visible & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: 0.3s;
  }

  .u-dt {
    width: 100%;
    height: 100%;
  }

  .u-dtc {
    float: left;
    vertical-align: middle;
  }

  @media #{md("s")} {
    position: relative;
    height: 40px;
    font-size: 1.25em;
    color: $grey-dark;
    text-align: left;
    background: $white;
  }
}

/* ATELIER SAINT GERMAIN */
.home-push {
  margin-bottom: $space * 7;

  .content {
    padding-top: 80px;

    svg {
      width: 100%;
      max-width: 280px;
    }

    img {
      max-width: 100%;
      height: auto;
      margin: 0 auto 40px;
    }

    p {
      text-align: left;
    }
  }
}

.logo-saintgermain {
  max-width: 182px;
}

.home-contact {
  padding: 6em 0;
  background: rgb(238, 238, 238);

  .home-contact__content {
    text-align: center;
  }

  .home-contact__subtitle {
    margin-bottom: 2em;
    font-size: 0.75em;
    font-weight: 700;
    color: #aaa;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }

  .home-contact__title {
    margin-bottom: 1em;
    font-size: 2.375em;
    font-weight: 500;
  }

  .home-contact__button span {
    font-size: 0.75em;
    color: #23282d;
  }

  .home-contact__button::after {
    z-index: 0;
    border: 1px solid #000;
  }
}

@media #{md("s")} {
  .home-push {
    .content {
      padding-top: 0;

      p {
        text-align: center;
      }
    }
  }

  .category-thumb__caption {
    display: block;
  }
}

@media #{md("l")} {
  .home-push {
    .content {
      padding-top: 80px;
    }
  }

  .logo-saintgermain {
    max-width: 300px;
  }
}
