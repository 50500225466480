.lookbook {
  &__container {
    @media #{md('s')} {
      margin-left: 6%;
    }
    @media #{md('m')} {
      margin-left: 0;
    }
  }

  &__item {
    $self: &;
    margin-bottom: 2em;
    padding-top: 2em;

    &.light {
      a {
        color: #fff !important;
      }

      #{$self} {
        &--more {
          &:after {
            background: #fff;
          }
        }
      }
    }

    &:nth-child(odd) {
      #{$self} {
        &--img {
          @media #{md('s')} {
            margin-top: -70%;
          }
        }
      }
    }

    &:hover {
      #{$self} {
        &--product {
          transform: translate3d(-150%, 0, 0);

          &:last-child {
            transform: translate3d(150%, 0, 0);
            transition-delay: 0.1s, 1s;
          }
        }

        &--more {
          &:after {
            animation: line 0.7s $in-out-circ forwards;
          }
        }

        &--img {
          img {
            //transform: scale3d(1.1, 1.1, 1);
            //transition: all 2s ease;
          }
        }
      }
    }

    &.js-reveal {
      #{$self} {
        &--title,
        &--more {
          opacity: 0;
          transform: translate3d(0, 30px, 0);
          transition: all 0.5s ease;
        }

        &--product {
          opacity: 0;
        }
      }

      &.is-visible {
        #{$self} {
          &--title,
          &--more {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition-delay: 0.3s;
          }

          &--more {
            transition-delay: 0.4s;
          }

          &--product {
            &.is-loaded {
              opacity: 1;
              transition-delay: 0s, 0.5s;
            }
          }
        }
      }
    }

    a {
      display: block;
      color: $grey-dark !important;
      text-decoration: none;
      background: none !important;

      img {
        //transition: all 0.4s;
        transform: scale3d(1, 1, 1);
        will-change: auto;
        //opacity: 0;

        &.is-loaded {
          //opacity: 1;
        }
      }
    }

    &--img {
      z-index: z('default', 10);
      display: block;
      width: 100%;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
      }
    }

    &--content {
      z-index: z('dropdown');
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      padding: 0 1.5em;
      text-align: center;
      transform: translate3d(0, -50%, 0);
    }

    &--title {
      display: block;
      margin-top: 0.4em;
      margin-bottom: 0.7em;
      font-size: 2.375em;
      font-weight: 500;
      line-height: 1.3em;
    }

    &--more {
      display: inline-block;
      font-size: 0.75em;
      font-weight: 500;
      line-height: 1.8em;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.2em;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: $grey-dark;
      }
    }

    &--product {
      z-index: z('default') !important;
      position: absolute;
      top: 50%;
      right: 0;
      display: none;
      width: 50%;
      margin-top: -10%;
      transition: transform 0.5s $in-out-circ, opacity 0s linear 1s;

      &:last-child {
        top: auto;
        right: auto;
        bottom: 50%;
        left: 0;
        margin-top: 0;
        margin-bottom: -10%;
      }

      @media #{md('m')} {
        display: block;
      }
    }

    @media #{md('s')} {
      margin-bottom: 6em;
      padding-top: 4em;
    }
  }
}
