.section__hotspot {
  margin-bottom: 3em;
  overflow: hidden;
}

.mapping {
  &__img {
    display: block;
    width: 100%;
    margin-bottom: 4em;

    img {
      display: block;
      width: 100%;
    }

    @media #{md('m')} {
      margin-bottom: 7em;
    }
  }

  &__point {
    position: absolute;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    transform: scale3d(1, 1, 1);
    will-change: auto;
    transition: all 0.4s $out-back-t;

    .js-reveal & {
      transform: scale3d(0, 0, 1);
    }

    .is-visible & {
      transform: scale3d(1, 1, 1);

      @for $i from 0 through 15 {
        &:nth-child(#{$i + 1}) {
          transition-delay: #{$i * 0.06s + 0.45s};
        }
      }
    }

    .round {
      @include round(30, 30);
      position: absolute;
      top: 0;
      left: 0;
      transform: scale3d(1, 1, 1);
      will-change: auto;
      transition: all 0.4s $out-back-t;

      @media #{md('xl')} {
        @include round(64, 64);
      }
    }

    &:hover {
      .round {
        transform: scale3d(1.1, 1.1, 1);
      }
    }

    span {
      display: block;
      width: 100%;
      height: 100%;
      transform: scale3d(1, 1, 1);
      will-change: auto;
      transition: all 0.4s $out-back-t;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 10px;
        margin-top: -5px;
        margin-left: -1px;
        background: $grey-dark;

        @media #{md('xl')} {
          width: 2px;
          height: 16px;
          margin-top: -8px;
          margin-left: -1px;
        }
      }

      &:after {
        transform: rotate(90deg);
      }
    }

    @media #{md('xl')} {
      width: 64px;
      height: 64px;
      margin-top: -32px;
      margin-left: -32px;
    }
  }

  &__popup {
    z-index: z('goku', 44);
    position: fixed;
    top: 50%;
    left: 50%;
    width: 560px;
    max-width: 85%;
    padding-top: 2em;
    padding-right: 2.5em;
    padding-bottom: 2em;
    padding-left: 2.5em;
    text-align: center;
    visibility: hidden;
    background: #fff;
    opacity: 0;
    transform: translate3d(-50%, -60%, 0);
    transition: all 0.4s $in-out-quad;

    &.active {
      visibility: visible;
      opacity: 1;
      transform: translate3d(-50%, -50%, 0);
      transition-delay: 0.2s;
    }

    .trigger-close {
      position: absolute;
      top: 30px;
      right: 30px;
    }

    .cart {
      z-index: z('dropdown');
      position: absolute;
      top: 40%;
      right: 100%;
      margin-top: -30px;
      margin-right: -40px;

      @media #{md('s')} {
        top: 50%;
        margin-top: -52px;
        margin-right: -52px;
      }
    }

    .trigger-wishlist {
      @include round(60, 60);
      display: inline-block;
      transition: all 0.4s $out-back-t;

      &:hover {
        transform: scale3d(1.1, 1.1, 1);

        svg {
          transform: translate3d(-50%, -50%, 0) scale3d(0.9, 0.9, 1);
        }
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 40%;
        transform: translate3d(-50%, -50%, 0);
        transition: all 0.4s $out-back-t;

        @media #{md('s')} {
          max-width: none;
        }
      }

      @media #{md('s')} {
        @include round(104, 104);
      }
    }

    h2 {
      margin-bottom: 1em;
      font-size: 2.375em;
      font-weight: 500;
      text-transform: none;
    }

    img {
      display: block;
      width: 100%;
      max-width: 270px;
      margin: auto;
      margin-bottom: 4em;
      box-shadow: 14px 14px 28px 1.2px rgba(0, 0, 0, 0.1);
    }

    @media #{md('m')} {
      max-width: 95%;
      padding-top: 4em;
      padding-right: 5em;
      padding-bottom: 4em;
      padding-left: 5em;
    }
  }
}
