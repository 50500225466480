.section__img-txt {
  overflow: hidden;

  img {
  }
}

.img-txt {
  &--img {
    display: block;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &--desc {
    .hentry {
      & > * {
        visibility: hidden;
        opacity: 0;
        transform: translate3d(0, 30px, 0);
        will-change: auto;
        transition: all 0.7s ease;

        @for $i from 0 through 30 {
          &:nth-child(#{$i + 1}) {
            transition-delay: #{0.05s * $i + 0.8s};
          }
        }
      }
    }

    &.is-visible {
      .hentry {
        & > * {
          visibility: visible;
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}
