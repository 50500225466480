.timeline {
  $date-height-xxs: 100px;
  $date-height-s: 100px;
  $date-height: 185px;
  margin-bottom: calc(5em + #{$date-height-xxs});
  transition: all 0.5s;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: none;
    max-height: 100%;
    margin-left: 8.33333%;
    opacity: 0.5;
    transform: translate3d(-30%, -30%, 0);
    transition: all 0.9s;

    .js-reveal & {
      opacity: 0;
      transform: translate3d(-30%, -30%, 0) scale3d(1.2, 1.2, 1);
    }

    .is-visible & {
      opacity: 0.5;
      transform: translate3d(-30%, -30%, 0) scale3d(1, 1, 1);
    }

    @media #{md('m')} {
      display: block;
      max-height: 80%;
      transform: translate3d(-30%, -20%, 0);
    }
    @media #{md('xl')} {
      max-height: 100%;
    }
  }

  &-item {
    $self: &;
    $delay: 0.75s;
    top: 0;
    display: none;
    align-items: center;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: all 0s linear $delay;

    .init-timeline & {
      position: absolute;
      display: block;

      @media #{md('s')} {
        display: flex;
      }
    }

    &.active {
      display: block;
      visibility: visible;
      opacity: 1;
      transition-delay: $delay;

      #{$self} {
        &--img {
          .is-visible & {
            transform: translate3d(0, 0, 0);
            transition-delay: $delay;

            img {
              transform: translate3d(0, 0%, 0);
              transition-delay: $delay;
            }
          }
        }

        &--desc {
          .is-visible & {
            .hentry {
              & > * {
                .elt {
                  visibility: visible;
                  opacity: 1;
                  transform: translate3d(0, 0, 0);
                }

                @for $i from 0 through 30 {
                  &:nth-child(#{$i + 1}) {
                    .elt {
                      transition-delay: #{0.05s * $i + $delay};
                    }
                  }
                }
              }
            }
          }
        }
      }

      @media #{md('s')} {
        display: flex;
      }
    }

    [class*='grid__col-m--'] {
      float: none;
    }

    .hentry {
      margin: 0;
    }

    &--desc {
      .hentry {
        & > * {
          overflow: hidden;

          .elt {
            display: block;
            visibility: hidden;
            opacity: 0;
            transform: translate3d(0, 20px, 0);
            will-change: auto;
            transition: all 0.4s ease;
          }

          @for $i from 0 through 30 {
            &:nth-child(#{$i + 1}) {
              .elt {
                transition-delay: #{0.05s * $i};
              }
            }
          }
        }
      }
    }

    &--img {
      display: block;
      width: 100%;
      overflow: hidden;
      visibility: visible;
      opacity: 1;
      transform: translate3d(-100%, 0, 0);
      will-change: auto;
      transition: all 0.7s $in-out-circ;

      img {
        display: block;
        width: 100%;
        transform: translate3d(100%, 0, 0);
        will-change: auto;
        transition: all 0.7s $in-out-circ;
      }
    }

    &--dates {
      position: absolute;
      bottom: #{$date-height-xxs * -1};
      left: 25%;
      white-space: nowrap;

      a {
        display: inline-block;
        margin-right: 80px;

        span {
          position: absolute;
          bottom: 30px;
          left: 0;
          font-family: 'Didot-HTF';
          font-size: 1.125rem;
          line-height: 1.2em;
          color: $grey;
          transform: rotate(-90deg) translate3d(0, 50%, 0);
          transform-origin: left bottom;
          transition: all 0.5s $in-out-circ 0.15s;

          .js-reveal & {
            opacity: 0;
            transform: rotate(-90deg) translate3d(20px, 50%, 0);
          }

          .is-visible & {
            opacity: 1;
            transform: rotate(-90deg) translate3d(0, 50%, 0);
          }

          @media #{md('m')} {
            bottom: 45px;
          }
        }

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          width: 1px;
          height: 24px;
          background: $grey;
          transform: scale3d(1, 1, 1);
          transform-origin: bottom center;
          will-change: auto;
          transition: all 0.4s $in-out-circ;

          .js-reveal & {
            transform: scale3d(1, 0, 1);
          }

          .is-visible & {
            transform: scale3d(1, 1, 1);

            @media #{md('m')} {
              transform: scale3d(1, 1.5, 1);
            }
          }

          @media #{md('m')} {
            transform: scale3d(1, 1.5, 1);
          }
        }

        &:hover {
          &:before {
            transform: scale3d(1, 1.5, 1);
          }
        }

        &.active {
          span {
            bottom: 0;
            font-size: 2em;
            color: $grey-dark;
            transform: rotate(-90deg) translate3d(0, 0, 0);
            transition-delay: 0s;

            .js-reveal & {
              opacity: 0;
              transform: rotate(-90deg) translate3d(20px, 0, 0);
            }

            .is-visible & {
              opacity: 1;
              transform: rotate(-90deg) translate3d(0, 0, 0);
            }

            @media #{md('s')} {
              font-size: 2.5em;
            }
            @media #{md('m')} {
              font-size: 4em;
            }
          }

          &:before {
            background: $grey-dark;
            transform: scale3d(1, 4, 1);
            transition-delay: 0.15s;

            .js-reveal & {
              transform: scale3d(1, 0, 1);
            }

            .is-visible & {
              transform: scale3d(1, 4, 1);

              @media #{md('s')} {
                transform: scale3d(1, 8.5, 1);
              }
              @media #{md('m')} {
                transform: scale3d(1, 11, 1);
              }
            }
          }
        }

        @media #{md('m')} {
          margin-right: 150px;
        }
      }

      @media #{md('s')} {
        bottom: #{$date-height-s * -1};
        left: 32%;
      }

      @media #{md('m')} {
        bottom: #{$date-height * -1};
        left: 32%;
      }
    }
  }

  @media #{md('s')} {
    margin-bottom: calc(7.5em + #{$date-height-s});
  }
  @media #{md('m')} {
    margin-bottom: calc(9.5em + #{$date-height});

    &.with-bg {
      box-sizing: content-box;
      padding-top: 4em;
    }
  }
}
