/*------------------------------------*\
		Pictogrammes
\*------------------------------------*/
.icon {
  display: inline-block;
  vertical-align: middle;

  svg {
    width: 100%;
  }
}

.icon--hearth-xs {
  width: 16px;
  height: 16px;

  path {
    fill: none !important;
    stroke: $grey !important;
    transform-origin: center;
    transition: all 0.9s ease;

    &:last-child {
      stroke: $grey-dark !important;
      stroke-dasharray: 70px;
      stroke-dashoffset: 70px;
    }
  }

  a:hover & {
    path {
      stroke-dashoffset: 0;
    }
  }
}

.icon--user {
  width: 16px;
  height: 16px;

  * {
    stroke: $grey !important;
  }
}

.icon--logout {
  width: 16px;
  height: 16px;

  * {
    fill: $grey !important;
  }
}

.icon--download-2021 {
  width: 17px;
}

/*------------------------------------*\
		Cross
\*------------------------------------*/
.icon--cross {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;

  span {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 2px;
    height: 80%;
    margin-left: -1px;
    overflow: hidden;
    background: $grey-light;
    transform: rotate(-45deg);

    &:first-child {
      transform: rotate(45deg);

      &:after {
        transition-delay: 0.1s;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $grey-dark;
      transform: translate3d(0, -100%, 0);
      transform-origin: left center;
      transition: all 0.5s $in-out-circ;
    }
  }

  &:hover {
    span {
      &:after {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

/*------------------------------------*\
		Logo Studio Meta
\*------------------------------------*/
.icon--sm {
  &,
  svg {
    display: block;
    width: 4.93em;
    height: 0.79em;
  }

  svg {
    fill: rgba($grey, 0.5);
  }

  &.icon--hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    overflow: hidden;

    background: $grey-dark;
    transition: all 0.4s $out-expo;

    svg {
      fill: $grey-lightest;
    }
  }
}
