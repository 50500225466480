/*---------------------------------------*
  Product
*---------------------------------------*/
@use 'sass:math';

@import '../base/variables';
@import '../tools/functions';
@import '../tools/mixins';

.section {
  padding-top: $space * 3;
  padding-bottom: $space * 3;
}

// Section that hold image and list of details
.section--details {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden; // For title before that expands to screen borders

  .cart {
    z-index: z('dropdown');
    position: absolute;
    top: 40%;
    left: 100%;
    margin-top: -30px;
    margin-left: -40px;
    margin-left: calc(#{1.5rem * -0.5} - 40px);

    @media #{md('s')} {
      top: 40%;
      margin-top: -52px;
      margin-left: calc(#{2rem * -0.5} - 52px);
    }
  }

  .trigger-wishlist {
    @include round(60, 60);
    display: inline-block;
    transition: all 0.4s $out-back-t;

    &:hover {
      transform: scale3d(1.1, 1.1, 1);

      svg {
        transform: translate3d(-50%, -50%, 0) scale3d(0.9, 0.9, 1);
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 40%;
      transform: translate3d(-50%, -50%, 0);
      transition: all 0.4s $out-back-t;

      @media #{md('s')} {
        max-width: none;
      }
    }

    @media #{md('s')} {
      @include round(104, 104);
    }
  }

  @media #{md("s")} {
    padding-top: 0;
    padding-bottom: $space * 3;
  }
}

// section titre produit
.section--title {
  padding-top: (2 * $space);
  padding-bottom: $space + $space-xs * 2;

  @media #{md("s")} {
    padding-top: (2 * $space);
    padding-bottom: (2 * $space);
  }
}

// Image
.product__figure {
  img {
    @include shadow;
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

// Liste
.product-details {
  @include reset-ul;
  height: 0;
  overflow: hidden;

  @media #{md("s")} {
    height: auto !important;
    padding-top: 0;
    overflow: visible;
  }
}

.product__content {
  padding-top: $space * 2;

  p {
    margin-bottom: 0;
  }
}

// Details techniques title, only show on mobile
.product-details__title {
  margin: $space 0;
  padding: $space 0;
  cursor: pointer;

  h2 {
    font-size: 1.15rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 4px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 200%;
    height: 100%;
    border: 1px solid $grey-lighter;
    transform: translate(-50%, 0);
  }
}

.product-details__icon {
  position: absolute;
  top: 50%;
  right: 0;
  fill: currentColor;
  transform: translate(0, -50%);
}

// Item
.product-details__item {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: $space-xs 0;
  font-size: 14px;

  &:last-of-type {
    margin-bottom: $space * 1.5;
  }

  @media #{md("s")} {
    padding: ($space-xs * 2) 0;
    border-bottom: 1px solid $grey-lighter;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

// Label
.product-details__label {
  font-family: $condensed;
  font-weight: 600;

  p {
    margin: 0;
  }
}

// Value
.product-details__value {
  margin: 0;
  font-weight: 300;
  text-align: right;
}

.product-details__value-link {
  font-weight: 600;
}

/*------------------------------------*
  Slider
*------------------------------------*/
$nav-slide-items-per-view: 4;
$nav-slide-width: 60px;
$nav-slide-gap: 15px;

.product-slider__main {
  .flickity-viewport {
    overflow: hidden;
  }
}

.product-slider__main-slide {
  display: block;
  width: 100%;

  img {
    z-index: z('default');
    display: block;
    width: 100%;
    height: auto;
  }

  &.is-selected {
    z-index: 1;
  }
}

.product-slider__zoom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.product-slider__nav {
  $items-space: $nav-slide-items-per-view * $nav-slide-width + ($nav-slide-items-per-view - 1) *
    $nav-slide-gap + ($nav-slide-gap + $nav-slide-width * 0.5);
  margin-top: $space;
  padding: 0 calc((100% - #{$items-space}) / 2);

  .flickity-viewport {
    overflow: hidden;
  }

  .flickity-prev-next-button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: $nav-slide-width;
    background-color: $white;
    border: 1px solid $grey-lighter;

    .flickity-button-icon {
      width: 10px;
    }

    &[disabled] {
      pointer-events: none;
      opacity: 0.2;
    }

    &.previous {
      left: 0;
    }

    &.next {
      right: 0;
    }
  }
}

.product-slider__nav-slide {
  display: block;
  width: $nav-slide-width;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: $grey-dark;
    opacity: 0.5;
    transition: opacity 0.3s ease;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &.is-nav-selected,
  &.is-nav-selected ~ * {
    &::after {
      opacity: 0;
    }
  }

  + .product-slider__nav-slide {
    margin-left: $nav-slide-gap;
  }
}

/*------------------------------------*
  Variation bullets
*------------------------------------*/
.section--variations {
  padding-top: $space * 2;
  padding-bottom: $space;
}

// List
.variations {
  @include reset-ul;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

// Item bullet
.variation {
  width: 80px;
  margin: 0 ($space-xs * 0.5) $space;
  overflow: hidden;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .variation__title {
    padding-top: $space-xs;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
    opacity: 0;
    transition: opacity 0.5s $out-expo;
  }

  &:hover::after {
    opacity: 1;
  }

  @media #{md("m")} {
    margin: 0 ($space-xs * 2) $space;
  }

  @media #{md("m")} {
    width: 100px;
  }
}

.variations__label {
  margin-bottom: 2 * $space;
}

/*------------------------------------*
  Buttons section
*------------------------------------*/
.product__buttons {
  @include clearfix;
  padding-bottom: $space-xs;
  text-align: center;

  .btn {
    margin: 0 $space $space;
  }
}

@media #{md("s")} {
  .section--download {
    padding-top: 3 * $space;
    // padding-bottom: 5 * $space;
  }

  .product__buttons {
    padding-bottom: 2 * $space;
  }
}

/*------------------------------------*
  Cross-sell
*------------------------------------*/

.cross-sell__title {
  padding-bottom: $space;

  @media #{md("s")} {
    padding-bottom: 0;
  }
}

.cross-sell__intro {
  p {
    margin-bottom: 0;
  }
}

.cross-sell__item {
  margin-bottom: $space * 2;

  a {
    display: block;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.3);
      opacity: 0;
      transition: opacity 0.5s $out-expo;
    }
  }

  .cross-sell__item-title {
    @extend .cross-sell__title;
    display: inline-block;
  }

  .cross-sell__item-title h4::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.4);
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 0.8s $out-expo;
  }

  &:hover {
    a::after {
      opacity: 1;
    }

    .cross-sell__item-title h4::after {
      transform: scaleX(1);
      transform-origin: 0 0;
    }
  }
}

/*------------------------------------*
  Downloads
*------------------------------------*/
.product__downloads {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  list-style-type: none;

  .tease-download {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: calc(#{math.div($space, $space)} * 1rem) 0;
  }

  > * {
    .tease-download {
      border-bottom: 1px solid $grey-lighter;
    }

    &:nth-child(-n + 2) {
      .tease-download {
        border-top: 1px solid $grey-lighter;
      }
    }
  }
}

/*------------------------------------*
  Project
*------------------------------------*/
.section--project {
  padding-top: 3 * $space;
  padding-bottom: 3 * $space;
}

.project__content {
  margin-bottom: $space;
}

@media #{md("s")} {
  .section--project {
    padding-bottom: 8 * $space;
  }

  .project__content {
    margin-bottom: 2 * $space;
  }
}

// Section grey background only on mobile
.section--grey-mobile {
  background-color: $grey-lightest;

  @media #{md("s")} {
    background-color: transparent;
  }
}

// Bloc that is centered, without columns
.bloc--center {
  max-width: 83.333333333%;
  margin: auto;
  text-align: center;

  @media #{md("xs")} {
    max-width: 50%;
  }

  @media #{md("xl")} {
    max-width: 33.33333%;
  }
}
