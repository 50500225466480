.quote {
  &__item {
    padding-top: 3em;
    padding-bottom: 3em;
    text-align: center;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-height: 100%;
      transform: translate3d(-50%, -50%, 0);

      &.js-reveal {
        opacity: 0;
        transform: translate3d(-50%, -50%, 0) scale3d(1.3, 1.3, 1);
        transition: all 0.5s ease;

        &.is-visible {
          opacity: 1;
          transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
          transition: all 2.5s ease;
        }
      }

      @media #{md('m')} {
      }
    }

    p {
      margin: 0;
      font-family: 'Didot-HTF';
      font-size: 2.6em;
      font-style: italic;
      font-weight: 500;
      line-height: 1.33em;

      &:before,
      &:after {
        content: '”';
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -0.25em;
        margin-right: 0.15em;
        font-size: 1.6em;
        font-style: normal;
        font-weight: 700;
        line-height: 1em;
        color: #333333;
        transform: translate3d(0, -50%, 0) rotate(-180deg);

        @media #{md('m')} {
          top: 50%;
          right: 100%;
          left: auto;
          font-size: 2.6em;
        }
        @media #{md('xl')} {
          font-size: 4.6em;
        }
      }

      &:after {
        top: auto;
        right: 0;
        bottom: 0;
        left: auto;
        margin-top: auto;
        margin-right: 0;
        margin-bottom: -1.3em;
        margin-left: 0.15em;
        transform: translate3d(0, -50%, 0);

        @media #{md('m')} {
          top: 50%;
          right: auto;
          bottom: auto;
          left: 100%;
          margin-top: 0.2em;
          margin-bottom: 0;
        }
      }

      @media #{md('m')} {
        font-size: 3.75em;
      }
    }

    @media #{md('s')} {
      padding-top: 3em;
      padding-bottom: 3em;
    }
    @media #{md('l')} {
      padding-top: 5.5em;
      padding-bottom: 5.5em;
      overflow: hidden;
    }
  }

  &__author {
    margin-top: 3.5em;
    font-size: 0.75em;
    font-weight: 600;
    color: $grey;
    text-transform: uppercase;

    &.js-reveal {
      opacity: 0;
      transform: translate3d(0, 30px, 0);
      transition: all 0.4s ease 0.15s;

      &.is-visible {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__container {
    margin-top: 2rem;
    margin-bottom: 5em;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    color: #fff;
    background: $grey-dark;

    @media #{md('s')} {
      margin-bottom: 7.5em;
    }
    @media #{md('m')} {
      margin-top: 0;
      margin-bottom: 10em;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
    }
    @media #{md('xl')} {
      padding-top: 2.5em;
      padding-bottom: 2.5em;
    }
  }

  &__testimonial {
    $self: &;

    &:first-child {
      .hentry {
        padding-top: 2em;

        @media #{md('s')} {
          padding-top: 3.5em;
        }
      }

      #{$self} {
        &--img {
          align-items: flex-start;
          margin-top: -5em;

          @media #{md('m')} {
            margin-top: -6.5em;
          }
        }
      }

      .inner-img {
        bottom: auto;
      }
      @media #{md('s')} {
        flex-direction: row-reverse;
      }
    }

    .inner-img {
      display: block;
      max-width: 100%;
      box-shadow: 14px 14px 24px 1px rgba($grey-dark, 0);

      img {
        display: block;
        max-width: 100%;
      }

      @media #{md('s')} {
        bottom: -3.5em;
      }
      @media #{md('xl')} {
        bottom: -6.5em;
      }
    }

    [class*='grid__col-m--'] {
      float: none;
    }

    &--img {
      display: flex !important;
      align-items: flex-end;

      &.js-reveal {
        .inner-img {
          box-shadow: 0px 0px 0px 0px rgba($grey-dark, 0.1);
          transition: transform 0.8s $in-out-circ, box-shadow 0.3s ease;
        }

        &.is-visible {
          .inner-img {
            box-shadow: 14px 14px 24px 1px rgba($grey-dark, 0.1);
            transition-delay: 0s, 1s;
          }
        }
      }
    }

    .hentry {
      margin-bottom: 4em;
      padding-top: 5em;

      p,
      ul,
      ol {
        color: $grey-mider;
      }

      @media #{md('s')} {
        margin-bottom: 7.5em;
        padding-top: 3.5em;
      }
    }

    @media #{md('s')} {
      display: flex;
    }
  }
}
