/*------------------------------------*\
		Footer
\*------------------------------------*/
.section--dark {
  color: #7e7e7e;
  background-color: #cdcecd;
}

.footer {
  margin-top: auto;
  padding-top: 2 * $space;
  padding-bottom: 3 * $space;
}

.footer__social,
.footer__logo,
.footer-menu,
.footer__signature,
.footer__newsletter {
  text-align: center;
}

.footer-menu,
.footer-social {
  padding-bottom: 1.5 * $space;

  @media #{md("s")} {
    padding-bottom: 0;
  }
}

@media #{md("s")} {
  .footer {
    padding-top: 5 * $space;
    padding-bottom: 5 * $space;
  }

  .footer__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1em;
  }

  .footer-social,
  .footer-menu,
  .footer__signature {
    flex-basis: 33.33333%;
  }

  .footer__menu {
    text-align: left;
  }

  .footer__signature {
    text-align: right;
  }
}

/*------------------------------------*
	Newsletter
*------------------------------------*/
.footer__newsletter {
  margin-bottom: $space;
}

@media #{md("s")} {
  .footer__newsletter {
    margin-bottom: 3 * $space;
  }
}

/*------------------------------------*
	Logo
*------------------------------------*/
.footer__logo {
  padding-bottom: 2 * $space;
  text-align: center;

  img {
    display: inline-block;
    max-width: 100%;
  }
}

/*------------------------------------*
	Footer menu
*------------------------------------*/
.footer-menu {
  @include reset-ul;

  @media #{md('s')} {
    margin-left: 8.3333%; // equivalent of col-push--1
    text-align: left;
    columns: 2;
  }
}

.footer-menu__item {
  font-weight: 700;
  line-height: 2;

  @media #{md('s')} {
    line-height: 3;
  }
}

.footer-menu__link {
  color: #7e7e7e;
  text-decoration: none;
  transition: 0.4s color $out-expo;

  &:hover {
    color: lighten($grey-light, 15);
    transition: 0.4s color $out-expo;
  }
}

/*------------------------------------*
	Footer Social
*------------------------------------*/
.footer-social {
  @include reset-ul;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.footer-social__item {
  display: inline-block;
  max-width: 30px;
  margin: 0 $space;

  a,
  svg {
    display: block;
    width: 100%;
    fill: #7e7e7e;
    transition: 0.4s fill $out-expo;

    &:hover {
      fill: lighten($grey-light, 15);
      transition: 0.4s fill $out-expo;
    }
  }
}

.logo--footer {
  padding: 0 0 $space * 1.5;
  text-align: center;
  fill: #7e7e7e;

  svg {
    width: 140px;
  }
}
