.hentry,
.mce-content-body {
  &.js-reveal {
    & > * {
      visibility: hidden;
      opacity: 0;
      transform: translate3d(0, 50px, 0);
      will-change: auto;
      transition: all 0.3s ease;
    }

    &.is-visible {
      & > * {
        visibility: visible;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition-duration: 0.7s;

        @for $i from 0 through 100 {
          &:nth-child(#{$i + 1}) {
            transition-delay: #{0.08s * $i + 0.3s};
          }
        }
      }
    }
  }

  h2 {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    font-size: 2.375em;
    font-weight: 500;
    line-height: 1.25em;
    white-space: normal;
  }

  h3 {
    margin-top: 1.4em;
    margin-bottom: 1.4em;
  }

  h4 {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    font-size: 0.75em;
    font-weight: 600;
    color: $grey;
    text-transform: uppercase;
    letter-spacing: 0.2em;

    strong {
      color: $grey-dark;
    }
  }

  p:not(.big-number),
  ul,
  ol {
    line-height: 2em;
  }

  p:not(.big-number) {
    color: $grey-mid;
  }

  .btn {
    margin-top: 1em;
    margin-right: 1.5em;
    margin-bottom: 1em;
    margin-left: 1.5em;
    font-size: 0.75em;
  }

  ul {
    @include reset-ul;
    column-count: 2;
    font-size: 0.75em;

    li {
      display: block;
      padding-left: 14px;

      &:before {
        content: '';
        position: absolute;
        top: $line-height * 0.5em;
        left: 1px;
        width: 4px;
        height: 4px;
        margin-top: 2px;
        background: $grey-dark;
        border-radius: 50%;
      }
    }

    &.caracteristics {
      margin-top: 1.4em;
      margin-bottom: 1.4em;
      color: $grey-dark;
      column-count: 1;

      li {
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
        padding-left: 0;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        border-bottom: 1px solid $grey-light;

        &:before {
          display: none;
        }

        &:first-child {
          border-top: 1px solid $grey-light;
        }

        a:not(.btn) {
          color: $grey;
          text-decoration: none;
          background: linear-gradient(to bottom, $grey 0%, $grey 100%);
          background-repeat: no-repeat;
          background-position: 0 100%;
          background-size: 100% 1px;
          transition: all 0.3s;

          &:hover {
            background-size: 0% 1px;
          }
        }
      }

      @media #{md('s')} {
        margin-left: 10%;
      }
      @media #{md('m')} {
        margin-left: 20%;
      }
    }
  }

  ol {
    @include reset-ul;
    font-size: 0.75em;
    counter-reset: list;

    li {
      display: block;
      padding-left: 20px;
      counter-increment: list;

      &:before {
        content: counter(list) '.';
        position: absolute;
        top: 0;
        left: 1px;
        color: $grey;
      }
    }
  }

  img {
  }

  .aligncenter {
    display: block;
    margin: auto;
    margin-top: 2em;
    margin-bottom: 2em;

    @media #{md('m')} {
      margin-top: 1em;
      margin-bottom: 1em;
      //display: inline-block;
      vertical-align: middle;
    }
  }

  .alignright {
    float: right;
    max-width: 100%;

    @media #{md('m')} {
      max-width: 40%;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    // Media queries
    @each $breakpoint in $breakpoints {
      $key: nth($breakpoint, 1);
      $grid-gutter: map-get($grid-gutters, $key);

      @media #{md($key)} {
        margin-left: $grid-gutter;
      }
    }
  }

  .alignleft {
    float: left;
    max-width: 100%;

    @media #{md('m')} {
      max-width: 40%;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    // Media queries
    @each $breakpoint in $breakpoints {
      $key: nth($breakpoint, 1);
      $grid-gutter: map-get($grid-gutters, $key);

      @media #{md($key)} {
        margin-right: $grid-gutter;
      }
    }
  }

  &:not(.mce-content-body) {
    .big-number {
      font-size: 10em;
      color: rgba($grey-light, 0.5);

      @media #{md('m')} {
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        font-size: 13rem;
        transform: translate3d(0, 10%, 0);
      }
    }
  }
}

ul.caracteristics {
  display: block;
}

p.big-number {
  margin: 0;
  font-family: 'Didot-HTF';
  font-size: 7rem;
  line-height: 1em;
  color: $grey-light;
}

.u-right {
  float: right !important;
}

.u-left {
  float: left !important;
}

.hentry {
  margin-bottom: 3.5em;

  @media #{md('s')} {
    margin-bottom: 3.5em;
  }
  @media #{md('m')} {
    margin-bottom: 6.5em;
  }
}

.section__hentry {
  margin-bottom: 1.5em;

  &.with-bg {
    .hentry {
      margin-top: 4.5em;

      @media #{md('s')} {
        margin-top: 5.5em;
      }
      @media #{md('m')} {
        margin-top: 7.5em;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;

    .hentry {
      //margin-top: 6.5em;
    }
  }

  & + .section__faq {
    margin-top: -7em;
  }

  @media #{md('s')} {
    margin-bottom: 2em;
  }
  @media #{md('m')} {
    margin-bottom: 3em;
  }
}
