.section__blocs {
  margin-bottom: 4em;

  &:last-child {
    margin-bottom: 6em;

    & + * {
      padding-top: 0;
    }
  }

  @media #{md('s')} {
    margin-bottom: 8em;
  }
  @media #{md('m')} {
    margin-bottom: -10em;

    & + * {
      padding-top: 14em;
    }
  }
}

.blocs {
  &__container {
    z-index: z('default');
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  &__item {
    $self: &;
    width: 100%;
    margin-top: -1px;
    margin-right: -1px;
    padding: 3em 2em;
    text-align: center;
    background: #fff;
    border: 1px solid $grey-light;

    &.dark {
      color: #fff;
      background: $grey-dark;
      border-color: $grey-dark;

      #{$self} {
        &--title {
          color: #fff;
        }

        &--desc {
          p {
            color: #fff;
          }
        }
      }

      .btn {
        color: #fff;

        &:before,
        &:after {
          border-color: #fff;
        }
      }
    }

    &.js-reveal {
      opacity: 0;
      transform: translate3d(0, 40px, 0);
      transition: all 0.6s;

      &.is-visible {
        opacity: 1;
        transform: translate3d(0, 0, 0);

        @for $i from 0 through 10 {
          &:nth-child(#{$i + 1}) {
            transition-delay: #{$i * 0.1s};
          }
        }
      }
      #{$self} {
        &--img {
        }
      }
    }

    &--img {
      display: block;
      max-width: 72px;
      margin: auto;
      margin-bottom: 1.5em;
    }

    &--title {
      margin: 0;
      margin-bottom: 1em;
      font-size: 1.25em;
      font-weight: 500;
      color: $grey-dark;
    }

    &--desc {
      p {
        font-weight: 300;
        color: $grey-dark;
      }
    }

    &--link {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    @media #{md('s')} {
      width: 50%;
    }

    @media #{md('m')} {
      width: 33.333%;
    }
    @media #{md('xxl')} {
      padding: 3.75em 4em;
    }
  }
}
