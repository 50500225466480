/*------------------------------------*
  Archive ressources
*------------------------------------*/

/*------------------------------------*
  Search
*------------------------------------*/
.ressources__search {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5rem;

  label {
    display: block;
    margin-bottom: 1rem;
  }

  input {
    width: 100%;
    padding: 1rem;
    padding-right: 28px;
    font-size: 0.8rem;
    border: solid 1px #aaa;
  }

  @media #{md('s')} {
    flex-direction: row;
    align-items: center;

    label {
      margin-bottom: 0;
    }

    input {
      width: auto;
    }
  }
}

/*------------------------------------*
  Filters
*------------------------------------*/
.filters__item {
  margin-bottom: 2rem;
  font-family: $condensed;

  label {
    display: block;
    margin-bottom: 2rem;
    color: #222;
  }

  select {
    display: none;
  }
}

.select-container {
  background-color: #fff;
}

.select {
  width: 100%;
  padding: 1rem 0.85rem;
  padding-right: 3rem;
  overflow: hidden;
  color: #444;
  text-align: left;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  border: 1px solid #aaa;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 6px solid #aaa;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    transform: translateY(-50%);
  }
}

.fake-select {
  z-index: 1;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  color: #444;
  text-align: left;
  border: 1px solid #aaa;
  border-top: 0;
}

.fake-select__btn {
  display: block;
  width: 100%;
  padding: 1rem 0.85rem;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #fff;

  &:hover {
    background-color: #d5d5d5;
  }
}

/*------------------------------------*
  List
*------------------------------------*/
.ressources__cat-name {
  font-family: $condensed;
  color: #222;

  .ressources__cat--level-0 & {
    margin-bottom: 3rem;
    font-size: 48px;
    font-weight: 600;
    line-height: 1.33;
  }

  .ressources__cat--level-1 & {
    margin-bottom: 2rem;
    font-size: 24px;
    line-height: 1.45;
  }
}

.ressources__cat--level-0 {
  margin-bottom: 5.25rem;
}

.ressources__cat--level-1 {
  margin-top: 3rem;
}

.ressources__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem 0;
  border-top: solid 1px #aaa;

  &:last-of-type {
    border-bottom: solid 1px #aaa;
  }

  @media #{md('s')} {
    flex-direction: row;
    align-items: center;
  }
}

.ressources__title {
  margin-bottom: 1rem;
  font-family: $condensed;
  color: #040404;

  @media #{md('s')} {
    margin-bottom: 0;
  }
}

/*------------------------------------*
  Loader
*------------------------------------*/
#ressources {
  padding-bottom: 5rem;

  &.is-loading {
    .loader {
      display: block;
    }

    #results {
      display: none;
    }
  }

  .loader {
    position: relative;
    display: none;
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }

  .loader div {
    position: absolute;
    box-sizing: border-box;
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 4px solid #000;
    border-color: #000 transparent transparent transparent;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  .loader div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .loader div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .loader div:nth-child(3) {
    animation-delay: -0.15s;
  }

  // Column Border
  @media #{md('m')} {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -8.33333%;
      width: 1px;
      height: 100%;
      background: #aaa;
    }
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

/*------------------------------------*
  Pagination
*------------------------------------*/
.pagination__item {
  padding: 0 $space !important;
  font-family: $condensed;
  font-size: 30px;
  font-weight: 300;
  color: $grey;
  text-decoration: none;
  cursor: pointer;
  transition: 0.4s color $out-expo;

  &.current {
    color: $grey-dark;
  }

  &:hover {
    color: $grey-dark;
    transition: 0.4s color $out-expo;
  }
}

.pagination {
  text-align: right;

  li {
    display: inline-block;

    &:last-of-type {
      .pagination__item {
        padding-right: 0;
      }
    }
  }
}
