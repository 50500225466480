.wishlist {
  $heightItem-xxs: 400px;
  $heightItem-m: 480px;
  $heightItem: 550px;

  &__container {
    @include clearfix;
    max-width: 94%;
    margin: auto;
    margin-bottom: 4em;

    .section__hentry + .section__hentry & {
      @media #{md('s')} {
        margin-top: -#{$heightItem-xxs};
      }
      @media #{md('m')} {
        margin-top: -#{$heightItem-m};
      }
      @media #{md('l')} {
        margin-top: -#{$heightItem};
      }
    }

    @media #{md('m')} {
      max-width: 100%;
      margin-bottom: 7em;
    }
    @media #{md('l')} {
      max-width: 94%;
    }
    @media #{md('xxl')} {
      margin-bottom: 10em;
    }
  }

  &__pop-list {
    max-width: 450px !important;
    padding: 2em 2.5em !important;
    background: #fff !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: 0 0 25px rgba($grey-dark, 0.2) !important;

    .close-modal {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    h3 {
      text-transform: uppercase;
    }

    ul {
      @include reset-ul;
      margin-top: 0.5em;
      margin-bottom: 2.5em;

      li {
        display: block;

        a {
          display: inline-block;
          margin-right: 20px;
          font-size: 0.75em;
          font-weight: 500;
          line-height: 1.6em;
          color: $grey;
          text-transform: uppercase;
          letter-spacing: 0.2em;

          &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background: $grey;
          }

          &:hover {
            &:after {
              animation: line 0.6s $in-out-circ forwards;
            }
          }
        }
      }
    }

    .btn {
      margin-top: 1.5rem;
    }
  }

  &__back {
    z-index: z('dropdown');
    position: absolute;
    top: #{$line-height * 2em};
    right: 1em;
  }

  &__item {
    float: left;
    width: 100%;
    width: calc(100% - 2em);
    height: $heightItem-xxs;
    margin: 0 1em;
    margin-top: 2em;
    margin-bottom: 3em;
    border: 1px solid $grey-lighter;

    &:first-child {
      .section__hentry + .section__hentry .wishlist__container & {
        @media #{md('s')} {
          margin-top: -5em;
          margin-left: calc(50% + 2em);
        }
        @media #{md('m')} {
          margin-top: 2em;
          margin-left: calc(#{33.3333% * 2} + 1.5em);
        }
        @media #{md('l')} {
          margin-top: 2em;
          margin-left: calc(#{33.3333% * 2} + 2.5em);
        }

        @media #{md('xl')} {
          margin-top: 0em;
          margin-left: calc(#{33.3333% * 2} + 5em);
        }
      }
    }

    a {
      text-decoration: none;
    }

    &--number {
      position: absolute;
      top: 0;
      left: 0;
      font-family: 'Didot-HTF';
      font-size: 13em;
      color: $grey-lightest;
      transform: translate3d(-50%, -40%, 0);
    }

    &--imgs {
      width: 100%;
      height: 62%;
      background-color: $grey-lightest;

      &.only-one {
        figure {
          height: 100% !important;
        }
      }

      .no-product {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        padding: 0 0.7em;
        font-size: 1.5em;
        font-weight: 500;
        line-height: 1.4em !important;
        text-align: center;
        transform: translate3d(0, -50%, 0);
      }

      figure {
        top: 75%;
        float: left;
        width: 25%;
        height: 25%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        opacity: 0;

        transition: all 0.4s ease;

        &.is-loaded {
          opacity: 1;
        }

        &:first-child {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 75%;
        }
      }

      @media #{md('m')} {
        height: 65%;
      }
    }

    &--content {
      @include clearfix;
      padding: 1.4em 2.5em;

      @media #{md('m')} {
        padding: 1.95em 2.5em;
      }
    }

    &--name {
      display: block;
      width: 100%;
      height: 1.5em;
      overflow: hidden;
      font-size: 1.5em;
      font-weight: 500;
      line-height: 1.5em;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: none;
    }

    &--product-number {
      display: block;
      margin: 1em 0;
      margin-bottom: 2.5em;
      font-size: 0.75em;
      font-weight: 500;
      color: $grey;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }

    &--url {
      float: left;

      strong {
        font-size: 0.75em;
        font-weight: 500;
        color: $grey-dark;
        text-transform: uppercase;
        letter-spacing: 0.2em;
      }

      span {
        font-size: 0.85em;
        color: $grey;
      }
    }

    &--more {
      float: left;
      margin-right: 20px;
      font-size: 0.75em;
      font-weight: 500;
      line-height: 1.6em;
      color: $grey;
      text-transform: uppercase;
      letter-spacing: 0.2em;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: $grey;
      }

      &:hover {
        &:after {
          animation: line 0.6s $in-out-circ forwards;
        }
      }
    }

    &--actions {
      float: right;

      a {
        display: inline-block;
        margin-left: 10px;

        &:hover {
          svg {
            * {
              fill: $grey-dark !important;
            }
          }
        }
      }

      svg {
        display: block;

        * {
          fill: $grey !important;
          transition: all 0.4s ease;
        }
      }
    }

    @media #{md('s')} {
      width: calc(50% - 4em);
      margin: 0 2em;
      margin-top: 2em;
      margin-bottom: 3em;
    }
    @media #{md('m')} {
      width: calc(33.3333% - 3em);
      height: $heightItem-m;
      margin: 0 1.5em;
      margin-top: 3em;
      margin-bottom: 3em;
    }
    @media #{md('l')} {
      width: calc(33.3333% - 5em);
      height: $heightItem;
      margin: 0 2.5em;
      margin-top: 4em;
      margin-bottom: 4em;
    }

    @media #{md('xl')} {
      width: calc(33.3333% - 10em);
      margin-right: 5em;
      margin-left: 5em;
    }
  }

  &__create {
    display: block;
    text-align: center;
    background: none !important;
    border: 2px dashed $grey-light;

    &:hover {
      .icon {
        transform: scale3d(1.1, 1.1, 1);

        span {
          transform: scale3d(0.9, 0.9, 1);
        }
      }
    }

    .icon {
      @include round(104, 104);
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -52px;
      margin-left: -52px;
      transition: all 0.4s $out-back-t;

      span {
        display: block;
        width: 100%;
        height: 100%;
        transform: scale3d(1, 1, 1);
        will-change: auto;
        transition: all 0.4s $out-back-t;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 2px;
          height: 16px;
          margin-top: -8px;
          margin-left: -1px;
          background: $grey-dark;
        }

        &:after {
          transform: rotate(90deg);
        }
      }
    }

    .txt {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      margin-top: 75px;
      font-size: 0.75em;
      font-weight: 600;
      line-height: 2em;
      color: $grey-dark;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }
  }
}
