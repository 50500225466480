/*------------------------------------*
	Catalogue
*------------------------------------*/
body.archive .archive-products .hero {
  height: 41vw;
  max-height: 730px;
  background-size: 100%;

  @media #{md("s", "max")} {
    height: 39vw;
  }

  @media #{md("xs", "max")} {
    height: 135vw;
  }
}

.catalogue {
  padding-bottom: $space * 2;

  @media #{md("l")} {
    padding-top: $space * 6;
    padding-bottom: $space * 4;
  }

  @media #{md("s")} {
    padding-top: $space * 3;
    padding-right: 0;
    padding-bottom: $space * 3;
    padding-left: 0;
  }
}

// Règle le bug de z-index sur le bouton reset du champ de recherche
.facetwp-search-wrap {
  .facetwp-btn {
    z-index: z('goku', 1);
  }
}

.filter-btn {
  z-index: z('default', +1);
  display: block;
  width: 100%;
  min-height: 64px;
  margin-bottom: $space * 2;
  padding: $space 0;
  font-family: $sans;
  color: $grey-dark;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border-bottom: 1px solid $grey-light;
  opacity: 1;

  &::before {
    content: attr(data-open);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $grey-dark;
    opacity: 0;
  }

  // &.is-fixed {
  // 	position: fixed;
  // 	top: 0;
  // 	width: 100%;
  // }
}

.catalogue-filters {
  z-index: z('default');
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: $space;
  padding-top: 84px;
  background-color: $white;
  transform: translateX(-101%);
  transition: 0.4s transform $out-expo;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  @media #{md("s")} {
    position: relative;
    padding: 0;
    transform: translateX(0) !important;

    &::after {
      content: '';
      clear: none;
    }
  }
}

.filters-active {
  .catalogue-filters {
    transform: translateX(0);
    transition: 0.4s transform $out-expo;
  }

  .filter-btn {
    color: $white;

    &::before {
      opacity: 1;
    }
  }
}

.woocommerce-result-count {
  display: none;
}

.catalogue__search {
  margin-bottom: $space;

  .form,
  .facetwp-facet-reference {
    display: flex;
    justify-content: space-between;
    min-width: 20em;
    max-width: 100%;
    margin-top: $space-xs * 2 !important;
    text-align: left !important;
    border: 1px solid $grey;

    .form__input,
    .facetwp-search {
      border: 0;
    }
  }

  @media #{md("s")} {
    margin-bottom: $space * 4;

    .form,
    .facetwp-facet-reference {
      min-width: 28em;
      margin-top: 0 !important;
    }
  }
}

.catalogue__link {
  display: block;
  text-decoration: none;

  &:hover {
    .catalogue__name {
      background-size: 100% 12px, 100% 12px;
      transition: 0.8s background-size $out-expo;
    }

    + .catalogue__rect {
      stroke-dashoffset: 0;
      transition: 1.2s all $out-cubic;
    }
  }
}

.catalogue__label {
  display: inline-block;
  padding-bottom: 4px;
  overflow: hidden;
  text-transform: capitalize;
}

.catalogue__rect {
  z-index: z('default', -1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  stroke: $grey-lighter;
  stroke-dasharray: 1160px;
  stroke-dashoffset: 1160px;
  transform: translateZ(0);
  transition: 0.8s all $out-cubic;
}

@media #{md("s")} {
  .catalogue__search {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .form,
    .facetwp-facet-reference {
      min-width: 28em;
      margin-left: $space !important;
    }
  }
}
/*------------------------------------*
	Products cards
*------------------------------------*/
.catalogue__container {
  margin-top: -$space;

  .facetwp-template {
    display: flex;
    flex-wrap: wrap;
  }
}

.catalogue__empty-message {
  display: block;
  margin-top: 3rem;
  text-align: center;
}

.catalogue__item {
  padding-top: $space;
  padding-bottom: $space;
  transition: all 0.8s $out-expo 0.2s;

  &:hover,
  &:active,
  &:focus {
    .catalogue__variations {
      display: block;
    }

    .catalogue__name {
      display: none;
    }
  }
}

.catalogue__figure {
  min-height: 190px;
  margin-bottom: $space;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .hover-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 0.8s $out-quad;

    &:hover {
      opacity: 1;
    }
  }

  @media #{md("xs")} {
    min-height: 350px;
  }

  @media #{md("s")} {
    min-height: 140px;
  }

  @media #{md("m")} {
    min-height: 140px;
  }

  @media #{md("l")} {
    min-height: 180px;
  }

  @media #{md("xl")} {
    min-height: 220px;
  }

  @media #{md("xxl")} {
    min-height: 250px;
  }
}

.catalogue__name {
  line-height: 1.25em;
  background-image: linear-gradient(to top, #ddd 0, #ddd 0),
    linear-gradient(to top, transparent 0, transparent 0);
  background-repeat: no-repeat, no-repeat;
  background-position: left bottom -10px, left bottom 6px;
  background-size: 0% 12px, 100% 12px;
  transition: 0.8s background-size $out-expo;
}

.catalogue__variations {
  display: none;
  color: $grey;
}

/*------------------------------------*
	Catalogue pending state
*------------------------------------*/

.js-blazy-collection {
  opacity: 0;
  transition: opacity 0.8s $out-expo;

  &.b-loaded {
    opacity: 1;
  }
}

.catalogue_loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-30px);
  transition: all 0.3s $out-expo 0;

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
  }

  .loader {
    position: relative;
    width: 10em;
    height: 10em;
    margin: 55px auto;
    font-size: 11px;
    color: #000;
    text-indent: -99999em;
    box-shadow: inset 0 0 0 1em;
    transform: translateZ(0) scale(0.3);
  }

  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
  }

  .loader:before {
    top: -0.1em;
    left: -0.1em;
    width: 5.2em;
    height: 10.2em;
    background: #fff;
    border-radius: 10.2em 0 0 10.2em;
    -webkit-transform-origin: 5.2em 5.1em;
    transform-origin: 5.2em 5.1em;
    -webkit-animation: load2 2s infinite ease 1.5s;
    animation: load2 2s infinite ease 1.5s;
  }

  .loader:after {
    top: -0.1em;
    left: 5.1em;
    width: 5.2em;
    height: 10.2em;
    background: #fff;
    border-radius: 0 10.2em 10.2em 0;
    -webkit-transform-origin: 0px 5.1em;
    transform-origin: 0px 5.1em;
    -webkit-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease;
  }
}

.catalogue.is-pending {
  .catalogue__item {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.8s $out-expo;
  }

  .catalogue_loader {
    opacity: 1;
    transform: none;
    transition: all 0.8s $out-expo 0.5s;
  }
}

@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
