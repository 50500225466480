/*------------------------------------*
	Main Navigation
*------------------------------------*/
.nav {
  z-index: 1401;
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: $white;
  transform: translateX(-100%);
  transition: 0.4s transform $out-expo;
}

/*------------------------------------*
	Open State
*------------------------------------*/
.is-open {
  .nav {
    padding: 2rem 0;
    transform: translateX(0);
    transition: 0.5s transform $out-expo;

    > .nav__list {
      overflow-y: auto;
    }
  }
}

// Ul
.nav__list:not(.nav__list--wpml) {
  @include reset-ul;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - 56px);
  padding: 0 $space;
}

// li
.nav__item {
  display: block;
  font-family: $condensed;
  text-decoration: none;
  letter-spacing: 2px;

  &.menu__item--product_cat {
    &::after {
      bottom: 10px;
    }
  }
}

.nav > .nav__list > .nav__item {
  margin-top: 2.2rem;

  &:first-child {
    margin-top: 1.5rem;
  }
}

.nav__list--wpml .nav__item {
  padding: 0 0.4em !important;

  &::after {
    display: none;
  }
}

.menu__item--wpml_ls_menu_item {
  padding: 0 2 * $space-xs;

  .nav__item {
    width: 100%;
    text-align: center;
  }

  &:hover {
    .nav__list--wpml {
      pointer-events: all;
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.nav__list:not(.nav__list--wpml) {
  & > .menu__item--wpml_ls_menu_item {
    &:after {
      @include triangle-down(10px, 7px, #000);
      content: '';
      position: absolute;
      top: 1 * $space-xs;
      right: -1 * 0.5 * $space-xs;
      display: inline-block;
    }
  }
}

@media #{md("s")} {
  .nav {
    position: relative;
    top: 0;
    min-height: auto;
    transform: translateX(0);

    & > .nav__list {
      align-items: flex-end;

      & > .nav__item {
        padding-bottom: $space * 0.5;
      }
    }
  }

  .nav__list:not(.nav__list--wpml) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;

    & > .menu__item--wpml_ls_menu_item {
      &:hover {
        &::after {
          @include triangle-up(10px, 7px, #000);
          background: transparent;
        }
      }
    }
  }

  .nav__list--wpml {
    position: absolute;
    top: 4 * $space-xs;
    left: 0;
    pointer-events: none;
    background-color: $white;
    opacity: 0;
    transform: translateY(-2 * $space-xs);
    transition: 0.2s all $in-out-cubic;

    .nav__item {
      padding: 2 * $space-xs;
    }
  }
}

@media #{md('s', 'max')} {
  .menu__item--product_cat::after {
    display: none;
  }

  .nav__list--wpml {
    display: none;
    margin-top: 2 * $space-xs;
  }

  .menu__item--wpml_ls_menu_item {
    padding-top: 2 * $space-xs;
    padding-bottom: 2 * $space-xs;
  }

  .nav__list:not(.nav__list--wpml) {
    & > .menu__item--wpml_ls_menu_item {
      &:after {
        top: 2.5 * $space-xs;
        right: 4 * $space-xs;
      }
    }
  }

  .is-wpml-ls-open {
    &::after {
      @include triangle-up(10px, 7px, #000);
    }

    .nav__list--wpml {
      display: block;
    }
  }

  .nav .login__link {
    padding-top: 2em;
    font-size: 0.8em;
    border-top: 1px solid #eee;
  }
}

.nav__list:not(.nav__list--wpml) {
  .menu__item--has-children {
    > a {
      pointer-events: none;
    }
  }

  .submenu {
    padding: 1rem 1rem 0;

    li {
      margin-bottom: 0.75rem;
    }

    .login__link {
      display: none;
    }
  }

  @media #{md("s")} {
    .submenu {
      z-index: z('dropdown');
      position: absolute;
      top: 2rem;
      left: -2rem;
      display: none;
      flex-direction: column;
      width: 250%;
      height: auto;
      padding: 2rem;
      background-color: $white;
      opacity: 0;
      transition: 0.4s opacity $out-expo;

      a {
        overflow: hidden;
        cursor: pointer;
      }
    }

    .menu__item--has-children {
      &:hover {
        .submenu {
          display: block;
          opacity: 1;
          transition: 0.4s opacity $out-expo;
        }
      }
    }
  }
}

.nav__list .menu__item--wpml_ls_menu_item {
  display: none;
}
