.open-detail-map {
  @media #{md('m')} {
    overflow: hidden;
  }
}

.map {
  &__search {
    margin-bottom: 5.5em;

    &.sending {
      button {
        pointer-events: none;

        span {
          opacity: 0;
          transition-delay: 0s;
        }

        &:after {
          opacity: 1;
          transition-delay: 0.2s;
        }
      }

      .trigger-geolocation {
        pointer-events: none;
      }
    }

    &.is-error input {
      border-color: red;
    }

    .trigger-geolocation {
      position: absolute;
      display: block;
      margin-top: 1em;
      font-size: 0.75em;
      font-weight: 600;
      color: $grey;
      text-align: right;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      white-space: nowrap;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: $grey;
      }

      &:hover {
        &:after {
          animation: line 0.8s $in-out-circ forwards;
        }
      }

      @media #{md('s')} {
        position: absolute;
        top: 50%;
        left: 100%;
        margin: 0 3em;
        transform: translate3d(0, -50%, 0);
      }
    }

    input {
      display: block;
      width: 100%;
      min-height: 4rem;
      padding: 0 1.25rem;
      padding-right: 8.5rem;
      font-size: 0.875em;
      font-style: italic;
      line-height: 4rem;
      color: #777;
      border: 1px solid $grey-light;
      transition: border-color 500ms ease;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      padding: 0 2em;
      font-size: 0.75em;
      font-weight: 600;
      line-height: 4rem;
      text-align: right;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 2em;
        width: 28px;
        height: 28px;
        margin-top: -14px;
        background: transparent url(#{$svg-dir}layout/loader.svg) center center no-repeat;
        background-size: contain;
        opacity: 0;
        transition: all 0.3s ease;
      }

      span {
        display: inline-block;
        line-height: 1.2em;
        vertical-align: middle;
        transition: all 0.3s ease 0.2s;

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 1px;
          background: $grey-dark;
          transform: scale3d(0, 1, 1);
          transform-orign: left center;
        }
      }

      &:hover {
        span {
          &:after {
            animation: line2 0.8s $in-out-circ forwards;
          }
        }
      }
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      opacity: 1;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      opacity: 1;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      opacity: 1;
    }

    @media #{md('m')} {
      margin-bottom: 7em;
    }
  }

  &__error {
    display: block;
    margin-bottom: 0.4rem;
    font-size: 0.75rem;
    color: red;
    opacity: 0;
    transition: opacity 500ms ease;

    &.is-error {
      opacity: 1;
    }
  }

  &__list {
    text-align: center;

    .pagination {
      margin-top: 2rem;
      margin-bottom: 2rem;
      transform: translate3d(0, 0, 0);
      transition: all 0.5s $in-out-circ;

      &.no-transition {
        transition: none;
      }

      .open-detail-map & {
        pointer-events: none;
        opacity: 0 !important;
        transform: translate3d(-100%, 0, 0);
      }
    }

    &--message {
      .no-result {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        margin-top: 1em;
        font-size: 1.1em;
        font-weight: 600;
        color: $grey;
        opacity: 0;
        transition: all 0.3s;

        &.show {
          opacity: 1;
        }
      }
    }

    &--stores {
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s $in-out-circ, transform 0.5s $in-out-circ;

      &:hover {
        .map__store--title {
          color: $grey;
        }
      }

      .open-detail-map & {
        display: none;
        pointer-events: none;
        opacity: 0;

        @media #{md('m')} {
          display: block;
          transform: translate3d(-100%, 0, 0);
        }
      }

      @media #{md('m')} {
        border-top: 0;
      }
    }

    @media #{md('m')} {
      min-height: 100vh;
    }
  }

  &__map {
    z-index: z('default', 10);
    width: 100%;

    &.fixed {
      &.bottom {
        @media #{md('m')} {
          position: absolute;
          top: auto;
          bottom: 0;
        }
      }
      @media #{md('m')} {
        position: fixed;
        top: 50px;
      }
    }

    .map {
      width: 100%;
      height: 40vh;

      @media #{md('m')} {
        height: 85vh;
      }
    }

    &--legend {
      padding: 3em 0;
      font-size: 0.75em;
      font-weight: 600;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 0.2em;

      span {
        display: block;
        padding: 5px;
        padding-left: 20px;
        vertical-align: middle;

        @media #{md('s')} {
          display: inline-block;
          padding: 0 20px;
        }

        @media #{md('m')} {
          padding: 0 10px;
        }

        @media #{md('l')} {
          padding: 0 20px;
        }
      }

      svg {
        top: -2px;
        display: inline-block;
        margin-right: 7px;
        vertical-align: middle;

        @media #{md('s')} {
          margin-right: 15px;
        }
      }

      @media #{md('s')} {
        padding: 4em 0;
        text-align: center;
      }
    }

    @media #{md('m')} {
      position: absolute;
      top: 0;
      left: 50%;
      width: 50vw;
      margin-bottom: 0;
      transform: translate3d(-100%, 0, 0);
    }
  }

  &__store {
    display: block;
    margin-top: -1px;
    overflow: hidden;

    &--base {
      display: none;
    }

    a {
      display: block;
      padding: 1.9em 80px;
      text-align: right;
      text-decoration: none;
      border-top: 1px solid $grey-lighter;
      border-bottom: 1px solid $grey-lighter;
      transition: all 0.4s;

      &:hover {
        z-index: z('default');
        border-color: $grey-dark;

        .map__store--type {
          * {
            stroke: $grey-dark;
          }
        }

        .map__store--title {
          color: $grey-dark;
        }

        .pins {
          opacity: 1;
        }
      }
    }

    &--type {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translate3d(0, -50%, 0);

      svg {
        display: block;

        * {
          transition: all 0.4s;
        }
      }
    }

    &--title {
      margin-bottom: 0;
      font-size: 1em;
      font-weight: 600;
      transition: all 0.4s;
    }

    &--address {
      margin: 0;
      font-size: 0.75em;
      color: $grey;
    }

    .pins {
      position: absolute;
      top: 50%;
      right: 20px;
      opacity: 0.2;
      transform: translate3d(0, -50%, 0);
      transition: all 0.4s;
    }
  }

  &__store-item {
    display: none;
    padding: 50px 2em;
    text-align: center;
    background: #fff;
    box-shadow: 0px 0px 30px rgba($grey-dark, 0.1);
    opacity: 0;
    transition: opacity 0.5s $in-out-circ, transform 0.5s $in-out-circ;

    &.show {
      opacity: 1;
      @media #{md('m')} {
        transform: translate3d(0, 0, 0);
      }
    }

    &.fixed {
      @media #{md('m')} {
        position: fixed;
        top: 50px;
        left: 58.33333%;
      }
    }

    .open-detail-map & {
      display: block;
      width: 100%;
    }

    .u-dt {
      width: 100%;
      height: 100%;

      .u-dtc {
        vertical-align: middle;
      }
    }

    h3 {
      margin-top: 0.4em;
      margin-bottom: 0.3em;
      font-size: 2.375em;
      line-height: 1.26em;
    }

    &--type {
      margin-bottom: 2.5rem;
      font-size: 0.75rem;
      font-weight: 600;
      color: $grey;
      text-transform: uppercase;
      letter-spacing: 0.1em;

      svg {
        display: block;
        margin: auto;
        margin-bottom: 15px;
      }
    }

    &--address {
      margin: 1em 0;
      color: $grey;
    }

    &--schedules {
      width: 85%;
      margin: 1.5em auto;
      line-height: 1.5em;
      color: $grey-dark;
      text-align: left;

      span {
        display: block;
      }

      i {
        float: right;
        font-style: normal;
      }

      @media #{md('m')} {
        margin: 2em auto;
      }

      @media #{md('xl')} {
        width: 70%;
        margin: 3.5em auto;
      }
    }

    &--close {
      display: inline-block;
      margin-top: 2.5em;
      font-size: 0.85em;
      font-weight: 600;
      color: $grey;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.2em;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: $grey;
      }

      &:hover {
        &:after {
          animation: line 0.8s $in-out-circ forwards;
        }
      }
    }

    &--contact {
      font-weight: 700;
      color: $grey-dark;
      text-decoration: none;

      a {
        text-decoration: none;
      }
    }

    @media #{md('m')} {
      z-index: z('default', 5);
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      transform: translate3d(120%, 0, 0);
    }
  }
}

.section__form-store-locator {
  margin-bottom: 3em;

  form {
    margin-top: 3em;
  }

  @media #{md('m')} {
    margin-bottom: 6em;
  }
}

.gm-style > div:last-of-type,
.gm-style > div:nth-of-type(14) {
  position: static;
}
