.woocommerce-error,
.woocommerce-message {
  @include reset-ul;
  z-index: z('goku');
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2em 4em;
  font-size: 1.2em !important;
  font-weight: 400;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background: $grey-dark;
  animation: hide 0.3s ease forwards 6s;
  column-count: 1 !important;
}

@keyframes hide {
  0% {
    visibility: visible;
    opacity: 1;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}
