/*------------------------------------*
	Pagination
*------------------------------------*/
@include fontfaces($fontfaces, $font-dir);

.pagination {
  padding-bottom: $space * 7;

  &__default {
    $self: &;
    font-size: 1.25em;
    font-weight: 500;
    color: $grey;
    text-align: center;

    a {
      text-decoration: none;
      background: none !important;
    }

    &--prev,
    &--next {
      display: block;
      margin-right: 1.5rem;

      &[href='#'] {
        pointer-events: none;
        opacity: 0.2;
      }

      &:hover {
        span {
          &:before {
            transform: translate3d(0, -50%, 0) scale3d(0.8, 1, 1);
          }
        }
      }

      span {
        display: inline-block;
        width: 30px;
        height: 1px;
        vertical-align: middle;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 100%;
          background: $grey-dark;
          transform: translate3d(0, -50%, 0);
          transform-origin: left center;
          transition: all 0.6s $in-out-expo;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 10px;
          height: 10px;
          margin-top: -5px;
          border-bottom: 1px solid $grey-dark;
          border-left: 1px solid $grey-dark;
          //transform-origin: left bottom;
          transform: rotate(45deg);
        }

        @media #{md('m')} {
          width: 100px;
        }
      }

      &#{$self}--next {
        margin-right: 0;
        margin-left: 1.5rem;

        span {
          &:before {
            transform-origin: right center;
          }

          &:after {
            right: 0;
            left: auto;
            transform: rotate(225deg);
          }
        }

        @media #{md('m')} {
          margin-right: 0;
          margin-left: 3rem;
        }
      }

      @media #{md('m')} {
        margin-right: 3rem;
      }
    }

    ul {
      @include reset-ul;
      column-count: 1;

      li {
        display: inline-block;
        padding: 0;

        &:before {
          display: none;
        }

        a {
          &:not(#{$self}--prev):not(#{$self}--next) {
            display: block;
            padding: 0 0.4em;
            transition: all 0.4s $out-back-t;

            &:hover {
              transform: scale3d(1.3, 1.3, 1);
            }

            &.current {
              color: $grey-dark;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

.pagination__nav {
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 70%;
    height: 1px;
    background-color: $grey;
    transform: translate(0, -50%);
  }

  @media #{md("xs")} {
    text-align: right;
  }
}

.pagination__list {
  @include reset-ul;
  @include clearfix;
  display: inline-block;
  padding-left: $space;
  background-color: $white;
}

.facetwp-pager-label {
  display: none !important;
}

.facetwp-page {
  display: inline-block;
  padding: 0 $space !important;
  font-family: $condensed;
  font-size: 30px;
  font-weight: 300;
  color: $grey;
  text-decoration: none;
  transition: 0.4s color $out-expo;

  &:last-of-type {
    padding-right: 0;
  }

  &.active {
    color: $grey-dark;
  }

  &:not(.dots) {
    cursor: pointer;
    &:hover {
      color: $grey-dark;
      transition: 0.4s color $out-expo;
    }
  }
}
