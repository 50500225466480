.user {
  &__pro {
    margin-top: 3em;
    margin-bottom: 5em;

    @media #{md('m')} {
      margin-top: 5em;
      margin-bottom: 10em;
    }
  }

  &__trigger {
    float: right;
    margin-top: 2.5rem !important;

    @media #{md('m')} {
      position: absolute;
      top: 50%;
      margin-top: 0rem !important;
      transform: translate3d(0, -50%, 0);
    }

    @each $breakpoint in $breakpoints {
      $key: nth($breakpoint, 1);
      $grid-gutter: map-get($grid-gutters, $key);

      @media #{md($key)} {
        right: $grid-gutter * 0.5;
      }
    }
  }
}
