@use 'sass:list';

$selectric-main-color: #aaa !default;
$selectric-secondary-color: #aaa !default;
$selectric-text-color: #444 !default;
$selectric-bg-color: #fff !default;
$selectric-btn-bg-color: #fff !default;
$selectric-height: 60px !default;
$selectric-spacing: 1.5rem !default;
$selectric-border-width: 1px !default;
$selectric-border-radius: 0 !default;
$selectric-inner-height: $selectric-height - ($selectric-border-width * 2) !default;
$selectric-font-size: 1em !default;

// Font size
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.select--contact {
  .selectric {
    .label {
      font-family: $sans;
      text-transform: initial;
    }
  }
}

.selectric {
  position: relative;
  overflow: hidden;
  background: $selectric-bg-color;
  border: $selectric-border-width solid $selectric-main-color;
  border-radius: $selectric-border-radius;

  .label {
    display: block;
    height: $selectric-inner-height;
    margin: 0 $selectric-inner-height 0 $selectric-spacing;
    overflow: hidden;
    font-family: $condensed;
    font-size: $selectric-font-size;
    line-height: $selectric-inner-height;
    color: $selectric-text-color;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    user-select: none;
  }

  .button {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: $selectric-inner-height;
    height: $selectric-inner-height;
    font: 0/0 a;
    *font: list.slash(20px, $selectric-inner-height) Lucida Sans Unicode, Arial Unicode MS, Arial;
    line-height: $selectric-inner-height;
    color: $selectric-secondary-color;
    text-align: center;
    background-color: $selectric-btn-bg-color;

    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 0;
      height: 0;
      margin: auto;
      border: 4px solid transparent;
      border-top-color: $selectric-secondary-color;
      border-bottom: none;
    }
  }
}

.selectric-focus .selectric {
  border-color: darken($selectric-main-color, 20%);
}

.selectric-hover .selectric {
  border-color: darken($selectric-main-color, 10%);

  .button {
    color: darken($selectric-secondary-color, 10%);

    &:after {
      border-top-color: darken($selectric-secondary-color, 10%);
    }
  }
}

.selectric-open {
  z-index: 9999;

  .selectric {
    border-color: darken($selectric-main-color, 10%);
  }

  .selectric-items {
    display: block;
  }

  &[class*='grid__col'] > .selectric-items {
    @each $breakpoint in $breakpoints {
      $key: nth($breakpoint, 1);
      $grid-gutter: map-get($grid-gutters, $key);

      @media #{md($key)} {
        margin-left: $grid-gutter * 0.5;
      }
    }
  }
}

.selectric-disabled {
  cursor: default;
  user-select: none;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.selectric-hide-select {
  position: relative;
  width: 0;
  height: 0;
  overflow: hidden;

  select {
    position: absolute;
    left: -100%;
  }

  &.selectric-is-native {
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;

    select {
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border: none;
      opacity: 0;
    }
  }
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  *font: 0/0 a !important;
  background: none !important;
  border: none !important;
  outline: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  display: block !important;
  visibility: hidden !important;
}
/* Items box */
.selectric-items {
  z-index: -1;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background: $selectric-bg-color;
  border: 1px solid darken($selectric-main-color, 10%);
  box-shadow: 0 0 10px -6px;

  .selectric-scroll {
    height: 100%;
    overflow: auto;
  }

  .selectric-above & {
    top: auto;
    bottom: 100%;
  }

  li,
  ul {
    min-height: 20px;
    margin: 0;
    padding: 0;
    font-size: $selectric-font-size;
    line-height: 20px;
    list-style: none;
  }

  li {
    display: block;
    width: 100%;
    padding: $selectric-spacing * 0.5 $selectric-spacing;
    line-height: 1.2em !important;
    color: #666;
    cursor: pointer;

    // &:first-of-type {
    // 	// First one is the label, we dno't need it in the list
    // 	display: none;
    // }

    &.selected {
      color: #444;
      background: #e0e0e0;
    }

    &.highlighted {
      color: #444;
      background: #d0d0d0;
    }

    &:hover {
      color: #444;
      background: #d5d5d5;
    }
  }

  .disabled {
    color: #666 !important;
    cursor: default !important;
    user-select: none;
    background: none !important;
    filter: alpha(opacity=50);
    opacity: 0.5;
  }

  .selectric-group {
    .selectric-group-label {
      padding-left: 10px;
      font-weight: bold;
      color: #444;
      cursor: default;
      user-select: none;
      background: none;
    }

    &.disabled li {
      filter: alpha(opacity=100);
      opacity: 1;
    }

    li {
      padding-left: 25px;
    }
  }
}
