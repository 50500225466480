/*------------------------------------*
	Archive jobs
*------------------------------------*/
$grey-job: #040404;
$letter-spacing: 2.4px;

/*------------------------------------*
	Sections
*------------------------------------*/
.section--big {
  padding-bottom: 5.5rem;
}

.archive-job__intro p:last-of-type {
  margin-bottom: 0;
}

/*------------------------------------*
	Tease
*------------------------------------*/
.tease-job {
  position: relative;
  border-bottom: solid 1px $grey;

  &:first-of-type {
    border-top: solid 1px $grey;
  }
}

.tease-job__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  font-family: $condensed;

  @media #{md('s')} {
    padding: 2.125rem 5rem;
  }
}

.tease-job__title {
  width: 100%;
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.33;
  color: #000;
  letter-spacing: $letter-spacing;

  @media #{md('s')} {
    width: 55%;
    margin-bottom: 0;
  }
}

.tease-job__city {
  margin: 0;
  line-height: 4;
  color: $grey-job;
  text-transform: uppercase;
}

/*------------------------------------*
	Apply
*------------------------------------*/
.apply {
  padding: 5.5rem 0;
  text-align: center;
  background-color: $grey-lightest;
}

.apply__inner {
  max-width: 50rem;
  margin: auto;
  padding: 0 2rem;
}

.apply__surtitle {
  display: block;
  margin-bottom: 2rem;
  font-family: $condensed;
  color: $grey-light;
  letter-spacing: $letter-spacing;
}

.apply__content {
  margin-bottom: 3.75rem;
}
