/*------------------------------------*\
		Form
\*------------------------------------*/

.form__container {
  padding-bottom: 2em;

  h2 {
    margin-bottom: 1rem;
  }

  @media #{md('m')} {
    padding-bottom: 4em;
  }
}

.form__item {
  margin-top: 1em;
  margin-bottom: 0.8em;

  @media #{md('m')} {
    margin-top: 1.5em;
    margin-bottom: 2em;
  }
}

.form__block {
  margin-bottom: 4em;
}

.lost_password {
  a {
    display: inline-block;
    font-size: 0.65em;
    font-weight: 600;
    color: $grey-dark;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.2em;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background: $grey-dark;
    }

    &:hover {
      &:after {
        animation: line 0.7s $in-out-expo;
      }
    }
  }
  @media #{md('m')} {
    margin-top: 1.5em;
  }
}

#wl-wrapper .btn {
  margin-right: 0;
  margin-left: 0;
}

/*------------------------------------*\
		Gravity Form
\*------------------------------------*/

.gform_wrapper {
  form {
    .gform_footer {
      display: block;
      // max-width: 150px;

      input[type='submit'] {
        text-transform: uppercase;
      }
    }

    .top_label {
      input {
        &:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not(
            [type='image']
          ):not([type='file']) {
          width: 100%;
          padding: 2 * $space-xs 2 * $space-xs !important;
          border: 1px solid #aaa;
        }

        &[type='submit'] {
        }
      }

      textarea {
        width: 100%;
        padding: 2 * $space-xs 2 * $space-xs !important;
        border: 1px solid #aaa;
      }

      label {
        &.gfield_label {
          display: none;
        }
      }
    }

    ul {
      li {
        &.gfield {
          margin-bottom: 1 * $space;
          clear: none;

          &.clearfix {
            clear: both;
          }
        }
      }
    }
  }
}

/*------------------------------------*\
		Labels
\*------------------------------------*/

// .form__label {}

/*------------------------------------*\
		Basic text input
\*------------------------------------*/

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1;
}

::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1;
}

:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1;
}

:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1;
}

.form__text {
  display: block;
  width: 100%;
  padding: 0 1.5rem;
  font-style: italic;
  line-height: 4rem;
  color: $grey-midest;
  appearance: none;
  border: 1px solid $grey-light;
}

/*------------------------------------*\
		Select list
\*------------------------------------*/

// .form__select {}

/*------------------------------------*\
		Checkbox
\*------------------------------------*/

.form__checkbox {
  position: absolute;

  &:checked {
    & + label {
      &:after {
        transform: scale3d(1, 1, 1);
      }
    }
  }

  & + label {
    display: block;
    margin: 2em 0;
    padding-left: 30px;
    font-size: 0.75em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    cursor: pointer;

    &:hover {
      &:before {
        transform: scale3d(1.2, 1.2, 1);
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      border: 1px solid $grey-light;
      transition: all 0.4s $out-back-t;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 12px;
      height: 12px;
      margin-top: -6px;
      margin-left: 4px;
      background: $grey-dark;
      transform: scale3d(0, 0, 1);
      transition: all 0.3s;
    }

    @media #{md('m')} {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
    }
  }
}

/*---------------------------------------*\
		Variations de formulaires
\*---------------------------------------*/

// .form--contact {}

/*------------------------------------*
	newsletter
*------------------------------------*/
.form {
  .form__input,
  .facetwp-search {
    width: 100%;
    border: 1px solid $grey;
  }

  @media #{md("s")} {
    display: flex;
    justify-content: space-between;
    text-align: left !important;
    border: 1px solid $grey;

    .form__input,
    .facetwp-search {
      border: 0;
    }
  }
}

.form__input,
.facetwp-search {
  width: 100%;
  padding: $space-xs * 2;
  font-size: 0.8em;
  font-style: italic;
}

.form__input--border {
  border: 1px solid $grey;
}

.form__input--separator {
  margin-top: 3.5rem !important;
}

.form__group {
  margin-bottom: $space;

  @media #{md("s")} {
    margin-bottom: 2 * $space;
  }
}

textarea {
  resize: none;
}

.gform_wrapper li.gfield.gfield_error {
  background-color: transparent !important;
  border-top: none !important;
  border-bottom: none !important;
}

.gform_wrapper .field_description_below .gfield_description {
  padding-top: 10px !important;
}

.gform_confirmation_message {
  @media #{md("s")} {
    margin-top: 10rem;
    font-size: 1.3em;
  }
}
