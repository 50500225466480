/*------------------------------------*
	Download link
*------------------------------------*/
$download_grey: #040404;

.download {
  display: inline-block;
  line-height: 2;
  color: $download_grey;
  vertical-align: middle;

  span {
    display: inline-block;
    margin-right: 1rem;
    line-height: 2;

    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: currentColor;
      transform: scaleX(1);
      transform-origin: right;
      transition: 0.3s transform $out-quad;
    }

    &:after {
      transform: scaleX(0);
      transform-origin: left;
      transition: 0.5s transform $out-quad;
    }
  }

  svg {
    display: inline-block;
    width: 17px;
    height: 22px;
    color: $grey-mid;
    vertical-align: middle;
  }

  &:hover {
    span {
      &:before {
        transform: scaleX(0);
        transition: 0.3s transform $out-quad;
      }

      &:after {
        transform: scaleX(1);
        transition: 0.5s transform 0.3s $out-quad;
      }
    }
  }
}
