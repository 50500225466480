/*------------------------------------*
	Typography helpers
*------------------------------------*/

// Titles styles
@use 'sass:math';

.t-large {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin-bottom: 0;
    font-family: $condensed;
    font-size: 2.15rem;
    //letter-spacing: 4px;
    //text-transform: uppercase;

    @media #{md("s")} {
      font-size: 2.5rem;
    }

    @media #{md("xl")} {
      font-size: 3rem;
    }

    @media #{md("xxl")} {
      font-size: 3.5rem;
    }
  }
}

.t-medium {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin-top: -0.25em;
    margin-bottom: $space;
    font-family: $condensed;
    font-size: 1.7rem;
    //text-transform: uppercase;

    @media #{md("s")} {
      margin-bottom: $space * 2;
      font-size: 2.75rem;
    }
  }
}

.t-medium-small {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin-bottom: 0;
    font-family: $condensed;
    font-size: 1rem;
    line-height: 1em;

    @media #{md("s")} {
      font-size: 1.7rem;
    }
  }
}

.t-small {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin-bottom: 0;
    font-family: $condensed;
    font-size: 1rem;
    line-height: 1em;
    //letter-spacing: 2px;
    //text-transform: uppercase;
  }
}

.t-x-small {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin-bottom: 0;
    font-family: $condensed;
    font-size: 0.75rem;
    line-height: 1em;
    //letter-spacing: 2px;
    //text-transform: uppercase;
  }
}

.t-bold {
  font-weight: 700 !important;
}

/*------------------------------------*
	Colors
*------------------------------------*/
.t-light {
  color: $grey;
}

/*------------------------------------*
	Spacings
*------------------------------------*/
.t-title {
  padding-bottom: $space * 0.5;

  @media #{md("s")} {
    padding-bottom: calc(#{math.div($space, $space)} * 1rem);
  }
}

.t-subtitle {
  padding-bottom: $space;

  @media #{md("s")} {
    padding-bottom: 2 * $space;
  }
}

/*------------------------------------*
	2021
*------------------------------------*/
.type-large {
  margin-bottom: 2rem;
  font-family: $condensed;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
  color: $grey-dark;
  letter-spacing: 4.8px;

  &.type-large--caps {
    text-transform: uppercase;
  }

  @media #{md('s')} {
    margin-bottom: 3.5rem;
    font-size: 3rem;
  }
}
