/*---------------------------------------*\
		Styles et réglages globaux
\*---------------------------------------*/
// Couleurs
@use 'sass:math';

$grey-dark: #222;
$grey: #aaa;
$grey-mid: #555555;
$grey-mider: #999999;
$grey-midest: #777;
$grey-light: #ccc;
$grey-lighter: #ddd;
$grey-lightest: #eee;
$white: #fff;

// Typographie
$font-size: 16px;
$line-height: 1.5;
$font-color: $grey-dark;
$selection-bg: $grey-dark;
$selection-color: $white;
$sans: 'sofia-pro', 'Helvetica Neue', Arial, sans-serif; //@TODO: update Typekit ?
$condensed: 'Avenir-Next', Times, sans-serif;

// Fontfaces
$font-dir: '/fonts/';
$fontfaces: (
  'Avenir-Next' 'avenir-next-regular' 400 normal,
  'Avenir-Next' 'avenir-next-medium' 500 normal
);
/*------------------------------------*\
		Layout
\*------------------------------------*/
// Layout
$layout-main--width: 100%;
$layout-main--max-width: 111em;

// Spacing units
$space: 1.5rem;
$space-xs: math.div($space, 3);

// Grille
$grid--columns: 12;
$grid-gutters: (
  'xxs': 1.5rem,
  'xs': 1.5rem,
  's': 2rem,
  'm': 2rem,
  'l': 3rem,
  'xl': 3rem,
  'xxl': 3rem,
);

/*------------------------------------*\
		Images and SVG dirs
\*------------------------------------*/
$img-dir: '/img/';
$svg-dir: '../../../svg/';

/*------------------------------------*\
		Z-indexes
\*------------------------------------*/
$layers: (
  'goku': 9000,
  'modal': 200,
  'dropdown': 100,
  'default': 1,
  'limbo': -999,
);

/*------------------------------------*\
		Media queries breakpoints
\*------------------------------------*/
$breakpoints: (
  'xxs': 0,
  'xs': 480,
  's': 768,
  'm': 992,
  'l': 1280,
  'xl': 1440,
  'xxl': 1920,
);

/*------------------------------------*\
		Animation
\*------------------------------------*/
@keyframes line {
  0% {
    transform: scale3d(1, 1, 1);
    transform-origin: right center;
  }

  50% {
    transform: scale3d(0, 1, 1);
    transform-origin: right center;
  }

  51% {
    transform: scale3d(0, 1, 1);
    transform-origin: left center;
  }

  100% {
    transform: scale3d(1, 1, 1);
    transform-origin: left center;
  }
}
@keyframes line2 {
  0% {
    transform: scale3d(0, 1, 1);
    transform-origin: left center;
  }

  50% {
    transform: scale3d(1, 1, 1);
    transform-origin: left center;
  }

  51% {
    transform: scale3d(1, 1, 1);
    transform-origin: right center;
  }

  100% {
    transform: scale3d(0, 1, 1);
    transform-origin: right center;
  }
}

/*------------------------------------*\
		Easing
\*------------------------------------*/
$in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

$in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

$in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

$in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

$in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$in-out-expo: cubic-bezier(1, 0, 0, 1);

$in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

$in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$out-back: cubic-bezier(0.175, 00.885, 0.32, 1.275);
$in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$out-back-t: cubic-bezier(0.535, 1.65, 0.635, 1.005);
