/*------------------------------------*\
		Buttons
\*------------------------------------*/

a.btn,
button.btn {
  display: inline-block;
  min-width: 19.25em;
  padding: 1.5rem 2.5rem !important;
  font-family: $condensed;
  font-size: 0.875em;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;

  span {
    z-index: z('default', 10);
    transition: all 0.3s;
  }

  &:before {
    content: '';
    z-index: z('default', -2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: 0.9s transform $out-expo;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border: 1px solid currentColor;
    transform: translate(0, -50%);
    will-change: auto;
    transition: 0.7s all $out-expo;
  }

  &--xxs {
    padding: 2.3em $space-xs * 3 !important;
    font-size: 0.75em;
  }

  &:hover {
    .btn__label,
    span {
      color: $grey-dark;
      transition: 0.7s color $out-expo;
    }
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0 0;
    }
  }

  &--white {
    span {
      color: #fff;
    }

    &:before {
      background-color: $white;
    }

    &:after {
      z-index: z('default', -1);
      border: 1px solid $white;
    }

    &:hover {
      .btn__label,
      span {
        color: $grey-dark;
      }
    }
  }
}

.btn__icon,
.btn__label {
  z-index: z('default');
  display: inline-block;
  line-height: 1em;
  vertical-align: middle;
  transition: 0.7s color $out-expo;
}

.btn__icon {
  padding-right: $space-xs;
}

/*------------------------------------*
	Color variations
*------------------------------------*/
.btn--white {
}

/*------------------------------------*
	Submit
*------------------------------------*/
.btn--submit {
  padding: $space-xs * 2 !important;
  font-family: $condensed;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
}

/*------------------------------------*
	Dropdown
*------------------------------------*/
.btn--dropdown {
  min-width: 6rem;
  padding: $space-xs * 2;
}

/*------------------------------------*
	Links
*------------------------------------*/
.link {
  font-family: $condensed;
  font-size: 0.875em;
  font-weight: 600;
  color: $grey;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
}

.link__label {
  display: inline-block;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform: translate(-50%, 0);
    transition: 0.8s all $out-expo;
  }

  &:hover {
    &:before {
      width: calc(100% + #{$space-xs} * 2);
      transform: translate(-50%, #{$space-xs});
      transition: 0.8s all $out-expo;
    }
  }
}

/*------------------------------------*
	Underline hover effect
*------------------------------------*/
.underline-effect {
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.4);
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 0.8s $out-expo;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: 0 0;
  }
}


/*------------------------------------*
	Optional hero buttons
*------------------------------------*/

.btn--hero {
  transition: color 0.3s ease-out;

  color: $white;

  &:hover {
    color: $grey-dark;
  }
}
