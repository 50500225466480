.faq {
  &__item {
    border-bottom: 1px solid $grey-light;

    .js-reveal & {
      opacity: 0;
      transform: translate3d(50px, 0, 0);
      transition: all 0.5s;
    }

    .is-visible & {
      opacity: 1;
      transform: translate3d(0, 0, 0);

      @for $i from 0 through 30 {
        &:nth-child(#{$i + 1}) {
          transition-delay: #{$i * 0.15s};
        }
      }
    }

    &:first-child {
      border-top: 1px solid $grey-light;
    }

    &--header {
      margin: 0;
      padding: 1.5em 0;
      padding-right: 2.5em;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5em;
      color: $grey-dark;
      cursor: pointer;

      svg {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);
        transition: all 0.4s $in-out-expo;

        * {
          stroke: $grey-dark !important;
        }
      }

      &.is-open {
        svg {
          transform: translate3d(0, -50%, 0) rotate(-180deg);
        }
      }
    }

    &--content {
      height: 0px;
      overflow: hidden;

      .hentry {
        margin: 0;
        padding-top: 0.5em;
        padding-bottom: 1.3em;
      }
    }
  }
}

.section__faq {
  margin-bottom: 6.5em;
}
