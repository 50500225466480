.contact-form {
  padding-bottom: 3 * $space;

  @media #{md("s")} {
    padding-bottom: 7 * $space;
  }
}

.subject__select {
  margin-bottom: 4 * $space;
}

.gform_footer {
  @include clearfix;
  margin-bottom: 4 * $space !important;
  padding: 0 !important;
}
