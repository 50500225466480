.breadcrumb {
  position: absolute;
  top: 1.2rem;
  left: 0;
  width: 100%;
  font-size: 0.75em;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.2em;

  .no-img & {
    color: $grey;
  }

  a {
    text-decoration: none;
    transition: all 0.4s;

    &:hover {
      opacity: 0.6;
    }
  }

  @media #{md('m')} {
    top: 2.5rem;
  }
}
