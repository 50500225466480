/*---------------------------------------*\
		Sélection
\*---------------------------------------*/

::-moz-selection {
  color: $selection-color;
  text-shadow: none;
  background: $selection-bg;
}

::selection {
  color: $selection-color;
  text-shadow: none;
  background: $selection-bg;
}

/*---------------------------------------*\
		Réglages globaux
\*---------------------------------------*/

*,
*:after,
*:before {
  position: relative;
  outline: none;
}

html {
  font-size: $font-size - 2;

  &.is-open {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  &:not(.is-ie) svg {
    height: auto;
  }

  @media #{md("s")} {
    font-size: $font-size - 1;
  }

  @media #{md("xl")} {
    font-size: $font-size;
  }
}

// Better font rendering for mac
.is-mac,
.is-mac input,
.is-mac button,
.is-mac textarea {
  @include antialiased;
}

/*------------------------------------*\
		Base typographique

		More settings:
		http://type-scale.com/
\*------------------------------------*/
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: $sans;
  font-size: 1em;
  font-weight: 300;
  line-height: $line-height;
  color: $font-color;

  &:after {
    content: '';
    z-index: z('goku');
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    background: rgba($grey-dark, 0.9);
    opacity: 0;
    transition: all 0.3s ease 0.2s;
  }

  .show-hotspot & {
    &:after {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
    }
  }
}

p,
ul,
ol,
blockquote {
  margin-bottom: $line-height * 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: $condensed;
  font-weight: 500;
}

small {
  font-size: 0.75em;
}

sup {
  font-size: 0.75em;
  vertical-align: super;
}

a {
  color: inherit;
  text-decoration: none;
}

.u-up {
  text-transform: uppercase;
}

.u-underline {
  text-decoration: underline;
}

.separator {
  display: block;
  width: 100%;
  overflow: hidden;
  border: 0;
  border-bottom: 1px solid $grey-lighter;
}
